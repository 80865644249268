import React from 'react';
import PropTypes from 'prop-types';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import {
  SectionHeaderContainer,
  SectionHeaderLeft,
  SectionHeaderRight,
  Title,
  Body,
  TitleText
} from './style';
import Overpass from '../../ui/typography/overpass';
import HtmlRaw from '../HtmlRaw';
import { Video, Image } from '../../atoms';

export const PageArticleTypes = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  FAQ: 'faq'
};

const ArticleText = ({
  title,
  body,
  mediaIsPhone,
  className = 'ArticleText-SectionHeaderLeft'
}) => (
  <>
    <SectionHeaderLeft lg={body ? 6 : 12} md={12} sm={12} className={className}>
      <TitleText>
        <Overpass
          type={
            mediaIsPhone ? 'sectionHeaderTitleMobile' : 'sectionHeaderTitle'
          }
        >
          {title}
        </Overpass>
      </TitleText>
    </SectionHeaderLeft>
    {body && (
    <SectionHeaderRight lg={6} md={12} sm={12}>
      {typeof body === 'string' ? (
        <Body>
          <Overpass htmlAttribute="span" type="sectionHeaderBody">
            <HtmlRaw html={body} />
          </Overpass>
        </Body>
      ) : (
        <Body>{body}</Body>
      )}
    </SectionHeaderRight>
    )}
  </>
);

const ArticleImage = ({
  title,
  body,
  image_url,
  mediaIsPhone,
  image_decoration,
  mediaIsTablet
}) => (
  <>
    <SectionHeaderLeft lg={5} md={12} sm={12}>
      <Title>
        <Overpass
          type={
            mediaIsPhone ? 'sectionHeaderTitleMobile' : 'sectionHeaderTitle'
          }
        >
          {title}
        </Overpass>
      </Title>
      {typeof body === 'string' ? (
        <Body>
          <Overpass htmlAttribute="span" type="sectionHeaderBody">
            <HtmlRaw html={body} />
          </Overpass>
        </Body>
      ) : (
        <Body>{body}</Body>
      )}
    </SectionHeaderLeft>
    <SectionHeaderRight
      lg={{ size: 5, offset: 2 }}
      md={12}
      sm={12}
      alignRight={!mediaIsPhone && !mediaIsTablet}
    >
      <Image
        imageUrl={image_url}
        x="right"
        y="top"
        maxWidth={mediaIsPhone ? 282 : 580}
        lines={image_decoration}
      />
    </SectionHeaderRight>
  </>
);

const ArticleVideo = ({
  title,
  body,
  image_url,
  video_file,
  mediaIsPhone,
  image_decoration,
  mediaIsTablet
}) => (
  <>
    <SectionHeaderLeft lg={5} md={12} sm={12}>
      <Title>
        <Overpass
          type={
            mediaIsPhone ? 'sectionHeaderTitleMobile' : 'sectionHeaderTitle'
          }
        >
          {title}
        </Overpass>
      </Title>
      {typeof body === 'string' ? (
        <Body>
          <Overpass htmlAttribute="span" type="sectionHeaderBody">
            <HtmlRaw html={body} />
          </Overpass>
        </Body>
      ) : (
        <Body>{body}</Body>
      )}
    </SectionHeaderLeft>
    <SectionHeaderRight
      lg={{ size: 5, offset: 2 }}
      md={12}
      sm={12}
      alignRight={!mediaIsPhone && !mediaIsTablet}
    >
      <Video
        imageUrl={image_url}
        videoUrl={video_file}
        x="left"
        y="top"
        maxWidth={mediaIsPhone ? 282 : 580}
        lines={image_decoration}
      />
    </SectionHeaderRight>
  </>
);

const PageArticle = ({
  title,
  body,
  image_url,
  video_file,
  image_decoration,
  type,
  mediaIsPhone,
  mediaIsTablet
}) => {
  const renderType = () => {
    switch (type) {
      case PageArticle.TYPE.IMAGE:
        return (
          <ArticleImage
            title={title}
            body={body}
            image_url={image_url}
            mediaIsTablet={mediaIsTablet}
            mediaIsPhone={mediaIsPhone}
            image_decoration={image_decoration}
          />
        );
      case PageArticle.TYPE.VIDEO:
        return (
          <ArticleVideo
            title={title}
            body={body}
            image_url={image_url}
            mediaIsTablet={mediaIsTablet}
            video_file={video_file}
            mediaIsPhone={mediaIsPhone}
            image_decoration={image_decoration}
          />
        );
      case PageArticle.TYPE.FAQ:
        return (
          <ArticleText
            title={title}
            body={body}
            mediaIsPhone={mediaIsPhone}
            className="FaqText-SectionHeaderLeft"
          />
        );
      case PageArticle.TYPE.TEXT:
      default:
        return (
          <ArticleText title={title} body={body} mediaIsPhone={mediaIsPhone} />
        );
    }
  };

  return <SectionHeaderContainer>{renderType()}</SectionHeaderContainer>;
};

PageArticle.propTypes = {
  title: PropTypes.string,
  body: PropTypes.any,
  type: PropTypes.string,
  image_url: PropTypes.string,
  video_file: PropTypes.string,
  image_decoration: PropTypes.bool,
  //HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired
};

PageArticle.TYPE = {
  TEXT: PageArticleTypes.TEXT,
  IMAGE: PageArticleTypes.IMAGE,
  VIDEO: PageArticleTypes.VIDEO,
  FAQ: PageArticleTypes.FAQ
};

PageArticle.defaultProps = {
  type: PageArticle.TYPE.TEXT,
  image_decoration: true
};

export default withMediaQueries(PageArticle);
