const colors = {
  primary: {
    darkGreen: '#0A3336', // 1
    green: '#006B68', // 2
    teal: '#00BF9A', // 3
    lightBlue: '#00C2DE', // 4
    yellow: '#F5C96E', // 5
    black5: 'rgba(0, 0, 0, 1)',
    black4: 'rgba(0, 0, 0, 0.8)',
    black3: 'rgba(0, 0, 0, 0.6)',
    black2: 'rgba(0, 0, 0, 0.4)',
    black1: 'rgba(0, 0, 0, 0.2)',
    white: '#fff'
  },
  secondary: {
    blueHeaderDark: '#373660',
    blueHeaderLight: '#6362ae',
    lightViolet: '#65658a',
    gray: '#666666',
    grayLighter: '#979797',
    grayDark: '#333333',
    manatee: '#8B8CA7',
    grayLight: '#999999',
    blueNumber: '#373660',
    bgYellowLight: '#fff9f2',
    bgGrayLight: '#fafafb',
    grayLightest: '#c9ccd4'
  }
};

export default colors;
