import { all } from 'redux-saga/effects';
import home from './home';
import navbar from './navbar';
import project from './project';
import plan from './plan';
import testimonials from './testimonials';
import partners from './partner';
import news from './news';
import faq from './faq';

export default function* rootSaga() {
  yield all([
    ...navbar,
    ...home,
    ...plan,
    ...testimonials,
    ...project,
    ...partners,
    ...news,
    ...faq
  ]);
}
