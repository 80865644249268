import { call, put, takeLatest, select } from 'redux-saga/effects';
import { GET_FAQ } from '../actions/faq';
import faqpage from '../../api/faq';
import { LOADER_OPEN, LOADER_CLOSE } from '../actions';

function* getFaqPage() {
  try {
    yield put({ type: LOADER_OPEN });
    const { faq: faqStored } = yield select(state => state.faq);
    if (Object.keys(faqStored).length === 0) {
      const res = yield call(faqpage.getFaq);
      const { data: { data: faq = {} } = {} } = res;
      yield put({ type: GET_FAQ._SUCCESS, faq });
    }
  } catch (error) {
    yield put({ type: GET_FAQ._ERROR, error });
    console.log('sagas > faq > getFaq', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getFaqPageWatcher() {
  yield takeLatest(GET_FAQ._REQUEST, getFaqPage);
}

export default [getFaqPageWatcher()];
