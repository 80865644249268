import homepage from './json/homepage.json';
import navbar from './json/bootstrap.json';
import project from './json/project.json';
import courseonline from './json/courseonline.json';
import courseclassrooom from './json/courseclassroom.json';
import aboutus from './json/aboutus.json';
import news from './json/news.json';
import newsitem from './json/newsitem.json';
import partners from './json/partner.json';
import partnerDetail from './json/partneritem.json';
import faq from './json/faq.json';

export const getBaseResponse = (
  { data = 'ok', code = 200, message = 'success' } = {},
  delay = 0
) =>
  new Promise(res => {
    setTimeout(() => {
      res({ data }, code, message);
    }, delay);
  });

export const hompageMock = () => getBaseResponse({ data: homepage });
export const navbarMock = () => getBaseResponse({ data: navbar });
export const projectMock = () => getBaseResponse({ data: project });
export const courseonlineMock = () => getBaseResponse({ data: courseonline });
export const courseclassroomMock = () =>
  getBaseResponse({ data: courseclassrooom });
export const aboutUsMock = () => getBaseResponse({ data: aboutus });
export const partnersMock = () => getBaseResponse({ data: partners });
export const partnerDetailMock = () => getBaseResponse({ data: partnerDetail });
export const newsMock = () => getBaseResponse({ data: news });
export const newsItemMock = () => getBaseResponse({ data: newsitem });
export const faqMock = () => getBaseResponse({ data: faq });
