import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { GET_COURSE_CLASSROOM } from '../../redux/actions/plan';
import { SectionTitle, CollaboratorsCard, Image } from '../../atoms';
import {
  CourseClassroomContainer,
  Article,
  Subtitle,
  CardContainer,
  PageFooter,
  PageFooterBody,
  PageFooterTitle,
  Partners,
  PartnersImages,
  PartnerTitle,
  PartnerCarouselContainer,
  BtnsWrapper
} from './style';
import {
  PageArticle,
  HtmlRaw,
  RowImages,
  PartnerCarousel,
  SectionDetail
} from '../../components';
import { Row, Col } from '../../ui/gridSystem';
import Overpass from '../../ui/typography/overpass';
import RowDetailsIcons from '../../components/RowDetailsIcons';
import { composeDefaultButton } from '../../utils/common';

const CourseClassroom = ({
  course_classroom,
  getCourseClassroom,
  mediaIsTablet,
  mediaIsPhone
}) => {
  useEffect(() => {
    getCourseClassroom();
  }, [getCourseClassroom]);

  // console.log('res', course_classroom);
  const course_info = Object.keys(course_classroom).reduce((acc, key) => {
    if (!course_classroom[key] || course_classroom[key]?.length <= 0) return { ...acc };
    switch (key) {
      case 'article_image':
      case 'title_articles_button_box':
      case 'images_list':
      case 'ending_article':
        if (!course_classroom[key]) return acc;
        return {
          ...acc,
          [key]: course_classroom[key][0].data
        };
      case 'title_button_box':
      case 'witenesses_button_box':
        return {
          ...acc,
          [key]: course_classroom[key]?.map(item => item.data)
        };
      default:
        return {
          ...acc,
          [key]: course_classroom[key]
        };
    }
  }, {});

  const {
    article_image,
    body,
    witenesses_button_box,
    title,
    partner_title,
    title_articles_button_box,
    images_list,
    ending_article,
    partner_logo_images,
    title_button_box
  } = course_info;

  const formatted_article_image = article_image
    ? {
      ...article_image.article[0].data,
      ...article_image.file[0].data.image
    }
    : {};

  const PartnerRowImagesConfig = [
    {
      x: Image.X.LEFT,
      y: Image.Y.BOTTOM,
      position: Image.POSITION.OUTSIDE,
      size: Image.SIZE.BIG
    },
    {
      lines: false
    },
    {
      x: Image.X.RIGHT,
      y: Image.Y.TOP,
      position: Image.POSITION.INSIDE
    }
  ];

  const getCardType = index => {
    const types = [
      CollaboratorsCard.TYPE.PRIMARY,
      CollaboratorsCard.TYPE.TERTIARY,
      CollaboratorsCard.TYPE.SECONDARY,
      CollaboratorsCard.TYPE.SECONDARY,
      CollaboratorsCard.TYPE.PRIMARY,
      CollaboratorsCard.TYPE.TERTIARY,
      CollaboratorsCard.TYPE.TERTIARY,
      CollaboratorsCard.TYPE.SECONDARY,
      CollaboratorsCard.TYPE.PRIMARY
    ];
    return types[index % 9];
  };

  return (
    <>
      <Helmet>
        <title>Palestre sostenibili - Classroom</title>
      </Helmet>
      <CourseClassroomContainer>
        <Article>
          <SectionTitle title={title} />
          <PageArticle
            type="image"
            image_url={formatted_article_image?.url}
            title={formatted_article_image?.title}
            body={formatted_article_image?.body}
          />
          <Subtitle>
            <Overpass
              type={
              mediaIsPhone
                ? 'classroomCourseSubtitlePhone'
                : 'classroomCourseSubtitle'
            }
              htmlAttribute="h3"
            >
              <HtmlRaw html={body} />
            </Overpass>
          </Subtitle>
        </Article>
        {witenesses_button_box?.length > 0 && (
        <CardContainer>
          <Row>
            {witenesses_button_box?.map((data, index) => (
              <Col
                className="p-0"
                key={`collaborator-${index}`}
                md={mediaIsTablet ? 12 : 6}
                sm={12}
              >
                <CollaboratorsCard data={data} type={getCardType(index)} />
              </Col>
            ))}
          </Row>
        </CardContainer>
        )}
        {title_button_box?.length > 0 && (
        <BtnsWrapper>
          {title_button_box.map((item, i) => (
            <SectionDetail
              key={`title-botton-${i}`}
              text={item?.title}
              button={composeDefaultButton(
                item?.button?.title,
                item?.button?.uri
              )}
            />
          ))}
        </BtnsWrapper>
        )}
        {partner_title && (
        <PartnerTitle>
          <Overpass type="partnerTitleCarousel" htmlAttribute="span">
            {partner_title}
          </Overpass>
        </PartnerTitle>
        )}
        {partner_logo_images && (
        <PartnerCarouselContainer>
          <PartnerCarousel
            partners={[...partner_logo_images].map(({ url }) => ({
              image: { url }
            }))}
          />
        </PartnerCarouselContainer>
        )}
        {(title_articles_button_box?.articles
        || title_articles_button_box?.title) && (
        <Partners>
          <RowDetailsIcons
            text={title_articles_button_box?.title}
            button={composeDefaultButton(
              title_articles_button_box?.button?.title,
              title_articles_button_box?.button?.uri
            )}
            content={title_articles_button_box?.articles}
          />
        </Partners>
        )}
        {images_list && (
        <PartnersImages>
          <RowImages
            images={images_list.images}
            configuration={PartnerRowImagesConfig}
          />
        </PartnersImages>
        )}
        {(ending_article?.title || ending_article?.body) && (
        <PageFooter>
          <PageFooterTitle>
            <Overpass htmlAttribute="span" type="courseOnlineFooterTitle">
              {ending_article?.title}
            </Overpass>
          </PageFooterTitle>
          <PageFooterBody>
            <Overpass htmlAttribute="span" type="courseOnlineFooterBody">
              <HtmlRaw html={ending_article?.body} />
            </Overpass>
          </PageFooterBody>
        </PageFooter>
        )}
      </CourseClassroomContainer>
    </>
  );
};
CourseClassroom.propTypes = {
  mediaIsTablet: PropTypes.bool,
  mediaIsPhone: PropTypes.bool,
  // HOC (connect, state)
  course_classroom: PropTypes.object.isRequired,
  // HOC (connect, dispatch)
  getCourseClassroom: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { course_classroom } = state.plan;

    return {
      course_classroom
    };
  },
  dispatch => ({
    getCourseClassroom: () => dispatch({ type: GET_COURSE_CLASSROOM._REQUEST })
  })
)(withMediaQueries(CourseClassroom));
