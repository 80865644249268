import styled from 'styled-components';

export const ContainerPlan = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  flex-direction: row;
  min-height: 650px;
  flex-direction: column;
`;
