import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from '../../../ui/gridSystem';
import Modal, { stopEvent } from '../Modal';

import { ModalGlobalContainer, ModalWrapper } from '../Modal.style';

import { Button } from '../../../atoms';
import { MODAL_CLOSE } from '../../../redux/actions';

const modalId = 'DemoModal';

const DemoModal = ({ onClose }) => (
  <Modal id={modalId}>
    <Container>
      <ModalWrapper>
        <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
          Demo Modal
          <Button onClick={onClose}>Close</Button>
        </ModalGlobalContainer>
      </ModalWrapper>
    </Container>
  </Modal>
);

DemoModal.propTypes = {
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired
};

DemoModal.id = modalId;

export default connect(
  () => ({}),
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE })
  })
)(DemoModal);
