import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { push } from 'redux-first-history';
import dayjs from 'dayjs';
import routes from '../../routes';
import {
  NewsContainer,
  NewsImage,
  NewsLabel,
  NewsTitle,
  NewsDetails,
  NewsDate
} from './styles';
import Overpass from '../../ui/typography/overpass';
import theme from '../../ui/theme';

const NewsCard = ({ news, isRecent, isInterest, pushUrl }) => {
  const { id, image, title, date, category } = news;

  const goToNewsDetails = () => {
    pushUrl(`${routes.news.path}/${id}`);
  };

  return (
    <NewsContainer
      isRecent={isRecent}
      isInterest={isInterest}
      onClick={goToNewsDetails}
    >
      <NewsImage src={image.url} />
      <NewsDetails>
        {isRecent ? (
          <NewsLabel>
            <Overpass htmlAttribute="span" type="newsLabel">
              News
            </Overpass>
          </NewsLabel>
        ) : (
          <NewsLabel>
            <Overpass
              htmlAttribute="span"
              type="newsLabel"
              configuration={{ color: theme.colors.primary.darkGreen }}
            >
              {category}
            </Overpass>
          </NewsLabel>
        )}
        <NewsTitle>
          <Overpass htmlAttribute="span" type="newsTitle">
            {title}
          </Overpass>
        </NewsTitle>
        <NewsDate>
          <Overpass htmlAttribute="span" type="newsDate">
            {dayjs(new Date(date * 1000)).format('DD-MM-YYYY')}
          </Overpass>
        </NewsDate>
      </NewsDetails>
    </NewsContainer>
  );
};

NewsCard.propTypes = {
  news: PropTypes.object,
  isRecent: PropTypes.bool,
  isInterest: PropTypes.bool,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired
};

NewsCard.defaultProps = {
  news: {},
  isRecent: false
};

export default connect(
  () => ({}),
  dispatch => ({
    pushUrl: url => dispatch(push(url))
  })
)(NewsCard);
