export const FontBase = 16;
export const NavBarHeight = 71;
export const NavBarHeightMobile = 0;
export const SubNavBarHeight = 0;
export const SubNavBarHeightMobile = 0;
export const LeftMainSideBarWdith = 0;
export const FooterHeight = 70;
export const FooterOffsetHeight = 0;
export const FooterHeightMobile = 116;
export const ContainerDesktop = 1180;

const ENV_MIXPANEL_KEY = {
  DEV: '',
  SIT: '',
  PRE_PROD: '',
  PROD: '',

  DEFAULT: ''
};

const ENV_GOOGLE_MAPS_KEY = {
  DEV: '',
  PRE_PROD: null,
  PROD: null,

  DEFAULT: ''
};

const APP_DEPLOY_ENV = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE.trim()
  : process.env.REACT_APP_STAGE;

export const MIXPANEL_KEY =
  ENV_MIXPANEL_KEY[APP_DEPLOY_ENV] || ENV_MIXPANEL_KEY.DEFAULT;
export const GOOGLE_MAPS_KEY =
  ENV_GOOGLE_MAPS_KEY[APP_DEPLOY_ENV] || ENV_GOOGLE_MAPS_KEY.DEFAULTa;
