import styled, { keyframes } from 'styled-components';
import Row from '../../ui/gridSystem/Row';
import Col from '../../ui/gridSystem/Col';
import { NavBarHeight } from '../../const';

const sectionSpace = 30;
const sectionHeight = 650;
const sectionHeightTablet = '100vh';
const sectionHeightMobile = 1024;

const SectionHeader = styled.section`
  width: 100vw;
  min-height: calc(${sectionHeight}px + 50px);
  height: calc(${sectionHeight}px + 50px);
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: -${NavBarHeight}px;
  padding: ${NavBarHeight}px 60px 0 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: hidden;

  @media ${(props) => props.theme.device.tablet} {
    min-height: ${sectionHeightTablet};
    height: auto;
    flex-direction: column;
    padding: 120px 30px 0 30px;
  }

  @media ${(props) => props.theme.device.mobile} {
    min-height: ${sectionHeightTablet};
    height: auto;
    flex-direction: column;
    padding: 81px 30px 0 30px;
  }
`;

const BackgroundLinearGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primary.darkGreen},
    ${({ theme }) => theme.colors.primary.green}
  );
  width: 100vw;
  height: calc(${sectionHeight}px + 50px);
  z-index: -1;

  @media ${(props) => props.theme.device.tablet} {
    height: 100vh;
  }

  @media ${(props) => props.theme.device.mobile} {
    height: 100vh;
  }
`;

const BackgroundLogo = styled.img`
  position: absolute;
  bottom: 8%;
  right: 10px;
  max-width: min(70%, 1000px);

  @media ${(props) => props.theme.device.tablet} {
    bottom: 0%;
    max-width: 95%;
  }
  @media ${(props) => props.theme.device.mobile} {
    bottom: 0%;
    max-width: 95%;
  }
`;
const BackgroundHeader = styled.img`
  position: absolute;
  bottom: 0;
  right: min(10%, 125px);
  max-width: 50%;

  @media ${(props) => props.theme.device.tablet} {
    max-width: 75%;
  }
  @media ${(props) => props.theme.device.mobile} {
    max-width: 75%;
  }
`;

const BackgroundLines = styled.img`
  position: absolute;
  bottom: 0;
  left: calc(50% + 340px);
  transform: translate(-50%, 0);

  @media ${(props) => props.theme.device.tablet} {
    left: 86%;
  }

  @media ${(props) => props.theme.device.mobile} {
    left: 95%;
    bottom: 0;
  }
`;

const HeaderInformation = styled.div`
  max-width: 450px;
  height: auto;
  z-index: 1;

  .main_block_button {
    margin-bottom: 20px;
  }

  .sub_main_block_button {
    margin-bottom: 57px;
  }

  .only_main_block {
    margin-bottom: 157px;
  }

  @media ${(props) => props.theme.device.tablet} {
    height: calc(100vh - ${NavBarHeight}px - 49px);
    max-width: unset;
    position: relative;

    .main_block_button {
      position: absolute;
      bottom: 100px;
      margin: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .sub_main_block_button {
      /* position: absolute;
      bottom: 20px; */
      margin: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    height: calc(100vh - ${NavBarHeight}px - 10px);
    max-width: unset;
    position: relative;

    .main_block_button {
      position: absolute;
      bottom: 100px;
      margin: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .sub_main_block_button {
      /* position: absolute;
      bottom: 20px; */
      margin: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
`;

const Edition = styled.div`
  margin-bottom: 20px;

  @media ${(props) => props.theme.device.tablet} {
    text-align: center;
  }

  @media ${(props) => props.theme.device.mobile} {
    text-align: center;
  }
`;

const Title = styled.div`
  margin-bottom: 40px;

  @media ${(props) => props.theme.device.tablet} {
    text-align: center;
  }

  @media ${(props) => props.theme.device.mobile} {
    text-align: center;
  }
`;

const ScrollDown = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;

  @media ${(props) => props.theme.device.tablet} {
    position: absolute;
    bottom: 40px;
  }
`;

const NewsCarrouselContainer = styled.div``;

const PartnerRowImages = styled.section`
  margin: 0;
  margin-top: 42px;

  @media ${(props) => props.theme.device.tablet} {
    margin-top: 50px;
    padding: 0 10px 0 20px;
  }

  @media ${(props) => props.theme.device.mobile} {
    margin-left: 20px;
    margin-top: 50px;
  }
`;

const SectionPartner = styled.section`
  margin: ${sectionSpace}px 0 50px 0;

  @media ${(props) => props.theme.device.tablet} {
    margin-bottom: 70px;
  }
`;

const PartnerRowQuote = styled(Row)`
  justify-content: center;
`;

const SectionProject = styled.section`
  min-height: ${sectionHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${sectionSpace}px 0;

  @media ${(props) => props.theme.device.tablet} {
    min-height: ${sectionHeightTablet};
    margin: 0;
  }
  @media ${(props) => props.theme.device.mobile} {
    min-height: ${sectionHeightMobile}px;
  }
`;

const ProjectRow = styled(Row)``;

const ColDetailProject = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  > div {
    + div {
      margin-top: 30px;
    }
  }

  @media ${(props) => props.theme.device.mobile} {
    margin-bottom: 60px;
  }
`;

const ColImageProject = styled(Col)`
  padding-right: 40px;
  display: flex;
  justify-content: flex-end;
  @media ${(props) => props.theme.device.mobile} {
    margin-top: 40px;
    justify-content: center;
  }
`;

const SectionNews = styled.section`
  margin-bottom: 56px;

  @media ${(props) => props.theme.device.tablet} {
    margin-bottom: 50px;
  }
`;

const NewsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 60px;

  @media ${(props) => props.theme.device.tablet} {
    margin-bottom: 40px;
  }

  @media ${(props) => props.theme.device.mobile} {
    width: 100vw;
    margin: 20px -20px 40px 0;
  }
`;

const NewsButtonContainer = styled.div`
  display: flex;
  button {
    margin: 0 auto;
  }
`;

const SectionPlan = styled.section`
  margin: ${sectionSpace}px 0;

  @media ${(props) => props.theme.device.tablet} {
    margin: 0 0 50px 0;
  }
`;

const TextBox = styled.div`
  margin-bottom: 40px;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary.white};
  }
`;

const scroll = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(7px);
  }
`;

const ScrollIcon = styled.div`
  width: 16px;
  height: 22px;
  position: relative;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
  margin-right: 8px;

  &:before {
    content: '';
    width: 3px;
    height: 3px;
    position: absolute;
    background: #fff;
    margin-left: 6.5px;
    top: 2px;
    border-radius: 4px;
    animation: ${scroll} 1.6s infinite;
  }
`;

export {
  SectionHeader,
  BackgroundHeader,
  BackgroundLogo,
  HeaderInformation,
  Edition,
  Title,
  ScrollDown,
  BackgroundLines,
  PartnerRowImages,
  SectionProject,
  ColDetailProject,
  ProjectRow,
  SectionPartner,
  PartnerRowQuote,
  SectionNews,
  NewsContainer,
  NewsButtonContainer,
  ColImageProject,
  SectionPlan,
  BackgroundLinearGradient,
  TextBox,
  NewsCarrouselContainer,
  ScrollIcon
};
