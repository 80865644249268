import styled from 'styled-components';
import { Row } from '../../ui/gridSystem';

export const CourseClassroomContainer = styled.div`
  margin-top: 50px;

  .section-title {
    margin-bottom: 19px;
  }
`;

export const Article = styled.div`
  height: 100%;
  width: 100%;

  @media ${(props) => props.theme.device.tablet} {
    padding: 0 80px;
  }

  .col-md-12:first-child {
    @media ${(props) => props.theme.device.tablet} {
      margin-bottom: 67px;
    }
  }
`;

export const Subtitle = styled.div`
  padding: 0 30px;
  margin-top: 78px;
  margin-bottom: 103px;

  @media ${(props) => props.theme.device.tablet} {
    padding: 0;
    margin-top: 54px;
    margin-bottom: 70px;
  }

  @media ${(props) => props.theme.device.mobile} {
    padding: 0;
    margin-top: 64px;
    margin-bottom: 70px;
  }
`;

export const Partners = styled(Row)`
  padding-left: 20px;
  margin-bottom: 42px;

  @media ${(props) => props.theme.device.tablet},
    ${(props) => props.theme.device.mobile} {
    padding: 0 20px;
  }
`;

export const PartnersImages = styled.div`
  margin: 100px 0 80px 0;
  padding-left: 20px;

  @media ${(props) => props.theme.device.tablet} {
    padding: 0 10px 0 20px;
  }

  @media ${(props) => props.theme.device.mobile} {
    padding-left: 20px;
    margin: 109px 0 80px 0;
  }
`;

export const PageFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;

  @media ${(props) => props.theme.device.tablet} {
    margin-bottom: 100px;
  }
`;

export const PartnerTitle = styled.div`
  margin-top: 45px;
  text-align: center;
`;

export const PartnerCarouselContainer = styled.div`
  margin-bottom: 76px;
  @media ${(props) => props.theme.device.tablet},
    @media ${(props) => props.theme.device.mobile} {
    margin-bottom: 86px;
  }
`;

export const PageFooterTitle = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

export const PageFooterBody = styled.div`
  text-align: center;
  a {
    color: ${({ theme }) => theme.colors.primary.lightBlue};
  }
`;

export const CardContainer = styled.div`
  margin-bottom: 76px;

  @media ${(props) => props.theme.device.tablet},
    @media ${(props) => props.theme.device.mobile} {
    margin-bottom: 50px;
  }
`;

export const BtnsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 50px;
  margin-bottom: 50px;

  @media ${(props) => props.theme.device.mobile},
    ${(props) => props.theme.device.tablet} {
    flex-direction: column;
    gap: 20px;
  }
`;
