import { apiCall, baseUrl, shouldUseMock } from './config';
import { hompageMock } from './mock';

export default {
  getHome: () => {
    if (shouldUseMock()) {
      return hompageMock();
    }
    return apiCall(`${baseUrl}homepage`, 'GET');
  }
};
