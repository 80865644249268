import styled from 'styled-components';
import { ContainerDesktop } from '../../../const';
import { Row, Col } from '../../../ui/gridSystem';

export const ContainerPlan = styled.div`
  min-height: 650px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ContainerPlanBg = styled.div`
  width: 100vw;
  position: absolute;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  flex-direction: row;
  height: 100%;

  @media ${props => props.theme.device.tablet && props.theme.device.mobile} {
    flex-direction: column;
  }
`;

export const PlanBoxContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: ${props => (props.first ? 'flex-end' : 'flex-start')};
  background-color: ${props => (props.color ? props.color : 'transparent')};
`;

export const PlanBoxContent = styled.div`
  width: 100%;
  max-width: calc(${ContainerDesktop}px / 2);
  padding: 0 110px;
  z-index: 9;
  flex-direction: column;
  display: flex;
  justify-content: center;

  @media ${props => props.theme.device.tablet} {
    max-width: unset;
    padding: 10px 84px;
  }
  @media ${props => props.theme.device.mobile} {
    max-width: unset;
    padding: 80px 29px;
  }

  div {
    &:nth-child(2) {
      margin: 30px 0;
    }
  }
`;

export const PlanBoxImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url(${props => (props.image ? `${props.image}` : '')});
  opacity: 0.2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
`;

export const RowPlan = styled(Row)`
  height: 100%;
  width: 100%;
  margin: 60px auto;
  min-height: 100%;
  flex-direction: row;
  flex: 1 1 auto;

  > div {
    &:nth-child(2) {
      padding-left: 110px;
    }
  }
`;

export const Content = styled.div`
  display: inline-block;
  min-height: 330px;
  flex: 180px;
  width: 100%;
  max-width: 480px;
  div {
    &:nth-child(2) {
      margin: 30px 0;
    }
  }
`;

export const ColContent = styled(Col)`
  display: block;

  div {
    color: ${({ color }) => color};
  }
`;
