import { apiCall, baseUrl, shouldUseMock } from './config';
import { projectMock } from './mock';

export default {
  getProject: () => {
    if (shouldUseMock()) {
      return projectMock();
    }
    return apiCall(`${baseUrl}project`, 'GET');
  }
};
