import styled, { css } from 'styled-components';
import medium_linee from '../../ui/assets/img/background/medium_linee.svg';
import linee from '../../ui/assets/img/background/linee.svg';

export const TestimonialsCardContainer = styled.div`
  margin: 40px 0;
  margin-left: 40px;

  @media ${props => props.theme.device.tablet} {
    margin-top: 50px;
    margin-left: 20px;
  }

  @media ${props => props.theme.device.mobile} {
    margin: 30px 0;
    margin-left: 20px;
  }
`;

const funzioneCalcoloStile = ({ theme, cardType }) => {
  let style;
  switch (cardType) {
    case 'primary':
      style = css`
        background-color: ${theme.colors.secondary.bgGrayLight};
      `;
      break;
    case 'secondary':
      style = css`
        background-color: ${theme.colors.secondary.bgYellowLight};
      `;
      break;
    case 'tertiary':
    default:
      style = css`
        background-color: ${theme.colors.primary.white};
      `;
      break;
  }
  return style;
};

export const CardContainer = styled.div`
  max-width: 360px;
  height: 362px;
  position: relative;

  @media ${props => props.theme.device.tablet} {
    max-width: 332px;
    min-height: 388px;
  }

  @media ${props => props.theme.device.mobile} {
    max-width: 282px;
    min-height: 414px;
    margin: 0 auto;
  }
`;

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  z-index: 11;
  ${props => funzioneCalcoloStile(props)};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;
`;

export const CommentContainer = styled.div`
  display: -webkit-box;
  height: 190px;
  margin-bottom: 10px;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media ${props => props.theme.device.tablet} {
    -webkit-line-clamp: 9;
    margin-bottom: 10px;
    min-height: 170px;
  }

  @media ${props => props.theme.device.mobile} {
    margin-bottom: 10px;
    min-height: 195px;
  }
`;

export const ImageProfile = styled.img`
  border-radius: 50px;
  border: none;
  height: 50px;
  width: 50px;
`;

export const CardDecoration = styled.div`
  background: url(${linee});
  position: absolute;
  top: -20px;
  left: -20px;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  @media ${props => props.theme.device.tablet} {
    background: url(${medium_linee});
    background-repeat: no-repeat;
  }

  @media ${props => props.theme.device.mobile} {
    background: url(${medium_linee});
    background-repeat: no-repeat;
  }
`;
