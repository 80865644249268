import styled from 'styled-components';

export const CurtainWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.colors.primary.black4};
  z-index: ${props => props.theme.zIndex.absoluteUp};
`;
