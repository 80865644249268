import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { Helmet } from 'react-helmet-async';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { PageArticle, PartnerCarousel, SectionDetail } from '../../components';
import {
  PartnersContainer,
  SectionPartners,
  Logo,
  ColumnPartner,
  Article,
  BtnsWrapper,
  ArticleWrapper
} from './style';
import { SectionTitle } from '../../atoms';
import { Row } from '../../ui/gridSystem';
import routes from '../../routes';
import { GET_PARTNERS } from '../../redux/actions/partners';
import render_body from '../../utils/render_body';
import Overpass from '../../ui/typography/overpass';
import {
  PartnerCarouselContainer,
  PartnerTitle
} from '../ClassroomCourse/style';
import { composeDefaultButton } from '../../utils/common';
import { feBaseUrl } from '../../api/config';

const Partners = ({ pushUrl, partners, getPartners, mediaIsPhone }) => {
  useEffect(() => {
    getPartners();
  }, [getPartners]);

  const handlerGoToPartnerDetail = (id) => {
    pushUrl(`${routes.partners.path}/${id}`);
  };

  const partnersPage = Object.keys(partners).reduce((section, key) => {
    switch (key) {
      case 'main_box':
        return {
          ...section,
          [key]: partners[key][0].data
        };
      case 'article':
        return {
          ...section,
          [key]: partners[key][0]
        };
      case 'title_button':
        return {
          ...section,
          [key]: partners[key]?.map((item) => item.data)
        };
      default:
        return {
          ...section,
          [key]: partners[key]
        };
    }
  }, {});
  const { partner_title, partner_logo_images, title_button, article } = partnersPage;

  return (
    <>
      <Helmet>
        <title>Partner e aziende coinvolte - Palestre Sostenibili</title>
        <meta
          name="description"
          content="Scopri i partners e le aziende coinvolte e come collaborare al progetto di Palestre Sostenibili."
        />
        <link rel="canonical" href={`${feBaseUrl}/aziende-partner`} />
      </Helmet>
      <PartnersContainer>
        <Article>
          <SectionTitle title={partnersPage?.title} />
          {partnersPage.main_box && (
          <PageArticle
            type={
              partnersPage?.main_box?.file[0]?.data?.video?.url
                ? 'video'
                : 'image'
            }
            body={partnersPage.main_box?.article?.[0]?.data?.body}
            title={partnersPage.main_box?.article?.[0]?.data?.title}
            image_url={partnersPage.main_box?.file?.[0]?.data?.image?.url}
          />
          )}
        </Article>
        {title_button?.length > 0 && (
        <BtnsWrapper>
          {title_button.map((item, i) => (
            <SectionDetail
              key={`title-botton-${i}`}
              text={item?.title}
              button={composeDefaultButton(
                item?.button?.title,
                item?.button?.uri
              )}
              className="flex-container"
            />
          ))}
        </BtnsWrapper>
        )}
        {(article || partnersPage.partner_items) && (
        <Row>
          <ArticleWrapper md={5} xs={12}>
            {render_body(article)}
          </ArticleWrapper>
          {partnersPage.partner_items && (
            <SectionPartners md={7} xs={12}>
              <Row>
                {partnersPage.partner_items.map((partner, i) => (
                  <ColumnPartner
                    lg={12 / (partnersPage.partner_items?.length || 6)}
                    md={12 / (partnersPage.partner_items?.length || 6)}
                    sm={partnersPage.partner_items?.length > 1 ? 6 : 12}
                    key={`ColumnPartner-${i}`}
                  >
                    <Logo
                      // onClick={() => handlerGoToPartnerDetail(partner.id)}
                      src={partner.image.url}
                    />
                  </ColumnPartner>
                ))}
              </Row>
            </SectionPartners>
          )}
        </Row>
        )}
        {partner_title && (
        <PartnerTitle>
          <Overpass type="partnerTitleCarousel" htmlAttribute="span">
            {partner_title}
          </Overpass>
        </PartnerTitle>
        )}
        {partner_logo_images && (
        <PartnerCarouselContainer>
          <PartnerCarousel
            partners={[...partner_logo_images].map(({ url }) => ({
              image: { url }
            }))}
          />
        </PartnerCarouselContainer>
        )}
      </PartnersContainer>
    </>
  );
};

Partners.propTypes = {
  mediaIsPhone: PropTypes.bool,
  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,

  // HOC (connect, state)
  partners: PropTypes.object.isRequired,

  // HOC (connect, dispatch)
  getPartners: PropTypes.func.isRequired
};

export default connect(
  (state) => {
    const { partners } = state.partners;

    return {
      partners
    };
  },
  (dispatch) => ({
    pushUrl: (url) => dispatch(push(url)),
    getPartners: () => dispatch({ type: GET_PARTNERS._REQUEST })
  })
)(withMediaQueries(Partners));
