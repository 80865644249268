import React from 'react';
import PropTypes from 'prop-types';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import PlanDesktop from './Desktop';
import PlanMobile from './Mobile';

const Plans = ({ plans, mediaIsPhone, mediaIsTablet }) => {
  if (mediaIsPhone || mediaIsTablet) {
    return <PlanMobile plans={plans} />;
  }

  return <PlanDesktop plans={plans} />;
};

Plans.propTypes = {
  plans: PropTypes.array,

  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired
};

export default withMediaQueries(Plans);
