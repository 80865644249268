import produce from 'immer';
import { GET_COURSE_CLASSROOM, GET_COURSE_ONLINE } from '../actions/plan';

const initialState = {
  course_online: {},
  course_classroom: {}
};

const planReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_COURSE_CLASSROOM._SUCCESS: {
        const { course_classroom } = action;
        draft.course_classroom = { ...course_classroom };
        break;
      }

      case GET_COURSE_ONLINE._SUCCESS: {
        const { course_online } = action;
        draft.course_online = { ...course_online };
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default planReducer;
