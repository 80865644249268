import styled, { css } from 'styled-components';

export const NewsImage = styled.div`
  width: 280px;
  height: 260px;
  top: 0;
  left: 0;
  background: url(${({ src }) => src}) no-repeat center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @media ${props => props.theme.device.tablet} {
    width: 228px;
    height: 212px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 100%;
    height: 233px;
  }
`;

export const NewsDetails = styled.div`
  padding: 20px;
  @media ${props => props.theme.device.mobile} {
    padding: 10px;
  }
`;

export const NewsLabel = styled.div``;

export const NewsTitle = styled.div`
  margin-top: 10px;

  display: -webkit-box;
  height: 60px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media ${props => props.theme.device.mobile} {
    height: 40px;
    -webkit-line-clamp: 2;
  }
`;

export const NewsDate = styled.div`
  margin-top: 20px;

  @media ${props => props.theme.device.mobile} {
    margin-top: 40px;
  }
`;

export const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary.white};
  width: 280px;
  height: 400px;
  cursor: pointer;

  @media ${props => props.theme.device.tablet} {
    width: 228px;
    height: 346px;
  }

  @media ${props => props.theme.device.mobile} {
    width: 100%;
    height: 388px;
  }

  ${({ isInterest }) =>
    isInterest &&
    css`
      @media ${props => props.theme.device.mobile} {
        width: 251px;
        height: 388px;
      }

      ${NewsTitle} {
        @media ${props => props.theme.device.tablet} {
          height: 60px;
          -webkit-line-clamp: 3;
        }

        @media ${props => props.theme.device.mobile} {
          height: 60px;
          -webkit-line-clamp: 3;
        }
      }

      ${NewsImage} {
        height: 180px;
      }

      ${NewsDate} {
        @media ${props => props.theme.device.tablet} {
          margin-top: 20px;
        }
        @media ${props => props.theme.device.mobile} {
          margin-top: 20px;
        }
      }
    `}

  ${({ isRecent }) =>
    isRecent &&
    css`
      ${NewsImage} {
        height: 180px;
      }

      ${NewsDetails} {
        padding-top: 30px;
      }

      ${NewsLabel} {
        display: initial;
        text-transform: uppercase;
      }

      ${NewsTitle} {
        margin-top: 10px;

        @media ${props => props.theme.device.tablet} {
          height: 40px;
          -webkit-line-clamp: 2;
        }

        @media ${props => props.theme.device.mobile} {
          height: 60px;
          -webkit-line-clamp: 3;
        }
      }

      ${NewsDate} {
        @media ${props => props.theme.device.tablet} {
          margin-top: 20px;
        }
        @media ${props => props.theme.device.mobile} {
          margin-top: 20px;
        }
      }

      @media ${props => props.theme.device.tablet} {
        width: 100%;
        flex-direction: row;
        height: 180px;

        ${NewsImage} {
          width: 313px;
          height: 180px;
        }
      }

      @media ${props => props.theme.device.mobile} {
        width: 100%;
        height: 400px;

        ${NewsImage} {
          width: 100vw;
          height: 180px;
        }
      }
    `}
`;
