import styled from 'styled-components';
import { Row, Col } from '../../ui/gridSystem';

export const CourseOnlineContainer = styled.div`
  margin-top: 50px;

  @media ${props => props.theme.device.tablet} {
    padding: 0 2px;
  }

  @media ${props => props.theme.device.mobile} {
    padding: 0 9px;
  }

  .section-title {
    margin-bottom: 15px;
  }
`;

export const Article = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 50px;

  &.central-article {
    margin-bottom: 70px;
  }
`;

export const CourseDetailList = styled(Row)`
  margin-bottom: 100px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 80px;
  }

  @media ${props => props.theme.device.mobile} {
    margin-bottom: 40px;
  }
`;

export const CourseDetailItem = styled(Col)`
  margin-bottom: 20px;
  @media ${props => props.theme.device.mobile} {
    margin-bottom: 40px;
  }
`;

export const CentralImage = styled.div`
  width: 100%;
  margin-bottom: 80px;
  max-height: 290px;

  @media ${props => props.theme.device.mobile} {
    margin-bottom: 70px;
  }
`;

export const Partners = styled(Row)`
  margin-bottom: 42px;

  @media ${props => props.theme.device.tablet},
    ${props => props.theme.device.mobile} {
    padding: 0 20px;
  }
`;

export const CardsList = styled.div`
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  margin-bottom: 100px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 95px;
  }

  @media ${props => props.theme.device.mobile} {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CardItem = styled.div`
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 40px;

  @media ${props => props.theme.device.desktop} {
    &:nth-child(4n + 0) {
      margin-right: 0;
    }
  }

  @media ${props => props.theme.device.tablet} {
    &:nth-child(even) {
      margin-right: 0;
    }
  }

  @media ${props => props.theme.device.mobile} {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const PartnerDetail = styled(Col)`
  @media ${props => props.theme.device.mobile} {
    margin-top: 40px;
  }
`;

export const PartnersImages = styled.div`
  margin: 100px 0 80px 0;
  @media ${props => props.theme.device.tablet} {
    padding: 0 10px 0 20px;
  }
  @media ${props => props.theme.device.mobile} {
    margin-left: 20px;
    margin: 109px 0 80px 0;
  }
`;

export const PageFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 100px;
  }
`;

export const PageFooterTitle = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

export const PageFooterBody = styled.div`
  text-align: center;
  a {
    color: ${({ theme }) => theme.colors.primary.lightBlue};
  }
`;
