import styled from 'styled-components';
import { Row } from '../../ui/gridSystem';

export const ImageTextRow = styled(Row)`
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  &:not(:first-of-type) {
    margin-top: 80px;
    @media ${props => props.theme.device.tablet} {
      margin-top: 120px;
    }
    @media ${props => props.theme.device.mobile} {
      margin-top: 76px;
    }
  }
  > div {
    &:first-of-type {
      @media ${props => props.theme.device.mobile} {
        margin-left: ${props => (props.reverse ? '0' : '20px')};
        margin-right: ${props => (props.reverse ? '20px' : '0')};
        margin-top: 0;
        max-width: calc(100% - 20px);
      }
    }
    &:nth-of-type(2) {
      margin-left: ${props => (props.reverse ? '0' : '50px')};
      margin-right: ${props => (props.reverse ? '50px' : '0')};
      display: flex;
      flex-direction: column;

      @media ${props => props.theme.device.mobile} {
        margin: 40px 0 0 0;
      }

      span {
        margin: 20px 0 40px;
      }
    }
  }

  button {
    align-self: flex-start;
  }

  @media ${props => props.theme.device.tablet} {
    padding-left: ${props => (props.reverse ? '0' : '20px')};
    padding-right: ${props => (props.reverse ? '20px' : '0')};
  }
`;
