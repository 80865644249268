import styled, { css } from 'styled-components';
import { NavBarHeight } from '../../../const';

const Header = styled.header`
  position: fixed;
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.headerFooter};
  height: ${NavBarHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.white};
  background-color: ${(props) => props.theme.colors.primary.green};
`;

const HeaderWrapper = styled.div`
  padding: 0 60px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media ${(props) => props.theme.device.desktopL} {
    padding: 0 20px;
  }
`;

const NavBarWrapper = styled.nav`
  margin-left: 40px;
`;

const Link = styled.a`
  div {
    text-transform: unset;
  }

  @media ${(props) => props.theme.device.desktopL} {
    text-align: center;
  }
`;

const NavBarItems = styled.ul`
  display: flex;
  align-items: center;
`;

const NavBarItem = styled.li`
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  display: flex;
  height: ${NavBarHeight}px;
  align-items: center;
  + li {
    margin-left: 40px;
  }

  ${({ hover, isActive }) =>
    (hover || isActive)
    && css`
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 6px;
        background: white;
        top: calc(100% - 6px);
      }
    `}
`;

const NavBarSubItemContainer = styled.div`
  position: absolute;
  min-width: 180px;
  top: 100%;
  left: 0;
  padding: 0 10px;
  background-color: ${(props) => props.theme.colors.primary.white};
  -webkit-box-shadow: 1px 1px 12px 0px rgba(50, 50, 50, 0.55);
  -moz-box-shadow: 1px 1px 12px 0px rgba(50, 50, 50, 0.55);
  box-shadow: 1px 1px 12px 0px rgba(50, 50, 50, 0.55);
`;

const NavBarSubItem = styled.div`
  padding: 10px 0;
  + div {
    border-top: 1px solid ${(props) => props.theme.colors.primary.black1};
  }
`;

const LinksContainer = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  button {
    margin-right: 40px;
    animation: fadein 0.5s;
    -ms-animation: fadein 0.5s;

    @media ${(props) => props.theme.device.desktopL} {
      white-space: pre-wrap;
      margin: 0 10px 0 15px;
    }
  }
`;

const Socials = styled.div`
  display: flex;
  margin-left: 60px;
  span {
    color: ${(props) => props.theme.colors.primary.white};
    + span {
      margin-left: 11px;
    }
  }
  text-transform: uppercase;

  @media ${(props) => props.theme.device.desktopL} {
    margin-left: 20px;
  }
`;

export {
  Header,
  HeaderWrapper,
  NavBarWrapper,
  Link,
  NavBarItems,
  NavBarItem,
  NavBarSubItemContainer,
  NavBarSubItem,
  LinksContainer,
  Socials
};
