import PropTypes from 'prop-types';

export const CarouselInterface = PropTypes.shape({
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  arrows: PropTypes.bool,
  swipeToSlide: PropTypes.bool,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number
});
