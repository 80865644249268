import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../theme';

export const TypefaceInterface = PropTypes.shape({
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  fontStyle: PropTypes.oneOf(['normal', 'italic']),
  fontWeight: PropTypes.oneOf([100, 200, 300, 400, 600, 700, 800, 900]),
  fontStretch: PropTypes.oneOf(['normal', 'condensed']),
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  letterSpacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontSize: PropTypes.number
});

const defaultConfig = {
  color: 'unset',
  fontFamily: theme.fontset.overpass,
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontStretch: 'normal',
  lineHeight: 1,
  letterSpacing: 'unset',
  fontSize: 12,
  textAlign: 'unset',
  textTransform: 'unset'
};

const getTypefaceBaseStyle = props => `
  color: ${props.config.color || props.theme.colors.primary.black};
  font-family: ${props.config.fontFamily || props.theme.fontset.overpass};
  font-size: ${props.config.fontSize}px;
  font-style: ${props.config.fontStyle};
  font-weight: ${props.config.fontWeight};
  font-stretch: ${props.config.fontStretch};
  letter-spacing: ${props.config.letterSpacing};
  line-height: ${props.config.lineHeight};
  text-align: ${props.config.textAlign};
  text-transform: ${props.config.textTransform};
  transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  width: 100%;
`;

export const TypefaceStyledDiv = styled.div`
  ${props => getTypefaceBaseStyle(props)};
`;

export const TypefaceStyledSpan = styled.span`
  ${props => getTypefaceBaseStyle(props)};
  width: unset;
`;

export const TypefaceStyledH1 = styled.h1`
  ${props => getTypefaceBaseStyle(props)};
  width: unset;
`;

export const TypefaceStyledH2 = styled.h2`
  ${props => getTypefaceBaseStyle(props)};
  width: unset;
`;

export const TypefaceStyledH3 = styled.h3`
  ${props => getTypefaceBaseStyle(props)};
  width: unset;
`;

export const TypefaceStyledH4 = styled.h4`
  ${props => getTypefaceBaseStyle(props)};
  width: unset;
`;

const Typeface = ({ configuration, children, htmlAttribute, onClick }) => {
  const config = { ...defaultConfig, ...configuration };
  let html = '';
  switch (htmlAttribute) {
    case 'div':
      html = (
        <TypefaceStyledDiv config={config} onClick={onClick}>
          {children}
        </TypefaceStyledDiv>
      );
      break;
    case 'h1':
      html = (
        <TypefaceStyledH1 config={config} onClick={onClick}>
          {children}
        </TypefaceStyledH1>
      );
      break;
    case 'h2':
      html = (
        <TypefaceStyledH2 config={config} onClick={onClick}>
          {children}
        </TypefaceStyledH2>
      );
      break;
    case 'h3':
      html = (
        <TypefaceStyledH3 config={config} onClick={onClick}>
          {children}
        </TypefaceStyledH3>
      );
      break;
    case 'h4':
      html = (
        <TypefaceStyledH4 config={config} onClick={onClick}>
          {children}
        </TypefaceStyledH4>
      );
      break;
    default:
      html = (
        <TypefaceStyledSpan config={config} onClick={onClick}>
          {children}
        </TypefaceStyledSpan>
      );
      break;
  }
  return html;
};

Typeface.propTypes = {
  children: PropTypes.node,
  configuration: TypefaceInterface,
  htmlAttribute: PropTypes.oneOf(['div', 'span', 'h3', 'h4', 'h6']),
  onClick: PropTypes.func
};

Typeface.defaultProps = {
  htmlAttribute: 'div'
};

export default Typeface;
