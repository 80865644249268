import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { ColDetailPartner, RowDetailsIconsContainer } from './style';
import { Row, Col } from '../../ui/gridSystem';
import { ButtonInterface } from '../../interface/button';
import { SectionTitle, DetailIcon } from '../../atoms';
import { SectionDetail } from '..';

const RowDetailsIcons = ({ title, text, button, content }) => (
  <RowDetailsIconsContainer>
    <Col lg={5} md={8} sm={12}>
      {title && <SectionTitle title={title} />}
      <SectionDetail text={text} button={button} />
    </Col>
    {content && (
      <Col lg={7} md={12} sm={12}>
        <Row>
          {content.map((itemDetail, i) => (
            <ColDetailPartner key={`partner-content-${i}`} md={12 / (content?.length || 12)} sm={12}>
              <DetailIcon
                text={itemDetail.data.body}
                icon={itemDetail.data.title}
              />
            </ColDetailPartner>
          ))}
        </Row>
      </Col>
    )}
  </RowDetailsIconsContainer>
);
RowDetailsIcons.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  button: ButtonInterface,
  content: PropTypes.array
};

export default connect()(withMediaQueries(RowDetailsIcons));
