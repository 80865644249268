import React from 'react';
import PropTypes from 'prop-types';
import { Title, SectionTitleContainer, Back } from './styles';
import Overpass from '../../ui/typography/overpass';
import Icon from '../Icon';
import theme from '../../ui/theme';

const SectionTitle = ({ title, back, onClickBack }) => (
  <SectionTitleContainer>
    {!!back && (
      <Back onClick={onClickBack}>
        <Icon type="icon-indietro" size={30} color={theme.colors.primary.teal} />
        <Overpass
          type="sectionTitle"
          htmlAttribute="span"
          configuration={{ color: theme.colors.primary.teal }}
        >
          {back}
        </Overpass>
      </Back>
    )}
    <Title>
      <Overpass type="sectionTitle" htmlAttribute="span">
        {title}
      </Overpass>
    </Title>
  </SectionTitleContainer>
);

SectionTitle.propTypes = {
  title: PropTypes.any,
  back: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default SectionTitle;
