import { apiCall, baseUrl, shouldUseMock } from './config';
import { newsMock, newsItemMock } from './mock';
import { parseQueryParams } from '../utils/queryParams';

export default {
  getNews: ({ params }) => {
    if (shouldUseMock()) {
      return newsMock();
    }
    return apiCall(`${baseUrl}news${parseQueryParams(params)}`, 'GET');
  },
  getNewsItem: ({ news_id }) => {
    if (shouldUseMock()) {
      return newsItemMock();
    }
    return apiCall(`${baseUrl}news/${news_id}/item`, 'GET');
  }
};
