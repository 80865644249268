import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Helmet } from 'react-helmet-async';
import {
  NewsContainer,
  NewsFilterContainer,
  NewsFilter,
  NewsListContainer,
  NewsItem,
  ViewMore,
  MessageOfEmpty
} from './style';
import { SectionTitle, CustomSelect, NewsCard, Icon } from '../../atoms';
import { GET_NEWS } from '../../redux/actions/news';
import Overpass from '../../ui/typography/overpass';
import theme from '../../ui/theme';

const News = ({ list, filters, getNews }) => {
  const [filterDate, setFilterDate] = useState(null);
  const [filterCategory, setFilterCategory] = useState(null);
  const [news, setNews] = useState([...list].splice(0, 30));

  useEffect(() => {
    getNews({ categoryId: filterCategory, date: filterDate });
  }, [filterCategory, filterDate, getNews]);

  useEffect(() => {
    setNews([...list].splice(0, 30));
  }, [list]);

  const messageEmptyArray = () => {
    if (filterDate === null && filterCategory === null) {
      return (
        <MessageOfEmpty>
          <Overpass htmlAttribute="span" type="emptyArrayMessage">
            Non sono presenti news
          </Overpass>
        </MessageOfEmpty>
      );
    }
    if (filterDate !== null && filterCategory !== null) {
      return (
        <MessageOfEmpty>
          <Overpass htmlAttribute="span" type="emptyArrayMessage">
            Non sono presenti news per la data e categoria selezionata
          </Overpass>
        </MessageOfEmpty>
      );
    }
    if (filterDate !== null && filterCategory === null) {
      return (
        <MessageOfEmpty>
          <Overpass htmlAttribute="span" type="emptyArrayMessage">
            Non sono presenti news per la data selezionata
          </Overpass>
        </MessageOfEmpty>
      );
    }
    if (filterDate === null && filterCategory !== null) {
      return (
        <MessageOfEmpty>
          <Overpass htmlAttribute="span" type="emptyArrayMessage">
            Non presenti news per la categoria selezionata
          </Overpass>
        </MessageOfEmpty>
      );
    }
    return null;
  };

  const onClickViewMore = () => {
    setNews([...list].splice(news.length, news.length + 30));
  };

  return (
    <>
      <Helmet>
        <title>Palestre sostenibili - News</title>
      </Helmet>
      <NewsContainer>
        <SectionTitle title="news" />
        <NewsFilterContainer>
          <NewsFilter>
            <CustomSelect
              options={filters.months}
              onChange={newValue =>
                setFilterDate(newValue ? newValue.value : newValue)}
            />
          </NewsFilter>
          <NewsFilter>
            <CustomSelect
              options={filters.categories}
              onChange={newValue =>
                setFilterCategory(newValue ? newValue.value : newValue)}
              placeholder="Tutte le categorie"
            />
          </NewsFilter>
        </NewsFilterContainer>
        <NewsListContainer>
          {news.length > 0
            ? [...news].map((item, index) => (
              <NewsItem key={`NewsItem-${index}`}>
                <NewsCard news={item} />
              </NewsItem>
            ))
            : messageEmptyArray()}
        </NewsListContainer>
        {list.length > news.length && (
        <ViewMore>
          <Overpass
            htmlAttribute="span"
            type="viewMore"
            onClick={onClickViewMore}
          >
            Vedi piu
          </Overpass>
          <Icon
            type="icon-indietro"
            size={30}
            color={theme.colors.primary.teal}
            rotate={-90}
            onClick={onClickViewMore}
          />
        </ViewMore>
        )}
      </NewsContainer>
    </>
  );
};

News.propTypes = {
  // HOC (connect, state)
  list: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,

  // HOC (connect, dispatch)
  getNews: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { list, filters } = state.news;

    return {
      list,
      filters
    };
  },
  dispatch => ({
    getNews: filters =>
      dispatch({ type: GET_NEWS._REQUEST, filters, new_set: true })
  })
)(News);
