import { apiCall, baseUrl, shouldUseMock } from './config';
import { aboutUsMock } from './mock';

export default {
  getAboutUs: () => {
    if (shouldUseMock()) {
      return aboutUsMock();
    }
    return apiCall(`${baseUrl}aboutus`, 'GET');
  }
};
