import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from '../../../atoms';
import { NavBarHeight } from '../../../const';
import {
  Header,
  HeaderWrapper,
  NavBarWrapper,
  NavBarItems,
  NavBarItem,
  NavBarSubItemContainer,
  NavBarSubItem,
  LinksContainer,
  Socials
} from './style';
import routes from '../../../routes';
import Overpass from '../../../ui/typography/overpass';
import theme from '../../../ui/theme';
import { handleClickNavigation } from '../../../utils/common';

const NavBarDesktop = ({
  current_path,
  menu,
  socials,
  signup,
  signin,
  news
}) => {
  const [scroll, setScroll] = useState(0);
  const [menuHover, setMenuHover] = useState();

  useEffect(() => {
    const updateScroll = () => setScroll(window.pageYOffset);
    window.addEventListener('scroll', updateScroll);
  }, []);

  const isHomePage = current_path === routes.home.path;
  const transparent = isHomePage && scroll <= NavBarHeight - 30;
  const subscribeButtonVisible = () =>
    !isHomePage || (isHomePage && scroll > 470);

  const onMenuFocus = ({ button: { title } }) => {
    setMenuHover(title);
  };

  const onMenuFocusOut = () => {
    setMenuHover('');
  };

  const isTabActive = (tab) => {
    let subMenu = [];
    if (tab.items) {
      subMenu = tab.items.map((item) => item.data.button.uri);
    }
    return (
      (!!tab.button.uri.length
        && tab.button.uri !== '/'
        && current_path.indexOf(tab.button.uri) >= 0)
      || subMenu.indexOf(current_path) >= 0
    );
  };

  const onClickMenuItem = (event, uri, hasChild) => {
    if (!hasChild) {
      handleClickNavigation(uri);
      event.stopPropagation();
    }
  };

  return (
    <Header transparent={transparent}>
      <HeaderWrapper>
        <Icon
          type="icon-logo-ps"
          size={40}
          color={theme.colors.primary.white}
          onClick={() => handleClickNavigation(routes.home.path)}
        />
        <NavBarWrapper>
          <NavBarItems>
            {menu
              && menu.map((item, i) => {
                if (item?.button?.uri === '/news' && !(news?.length > 0)) {
                  return null;
                }
                return (
                  <NavBarItem
                    onClick={(event) =>
                      onClickMenuItem(event, item.button.uri, !!item.items)}
                    key={`navbar-item-${i}`}
                    hover={!!item.items && item.button.title === menuHover}
                    isActive={isTabActive(item)}
                    onMouseEnter={() => onMenuFocus(item)}
                    onMouseLeave={onMenuFocusOut}
                  >
                    <Overpass htmlAttribute="span" type="menuHeader">
                      {item.button.title}
                    </Overpass>
                    {!!item.items && item.button.title === menuHover && (
                      <NavBarSubItemContainer>
                        {[...item.items].map((subitem, index) => (
                          <NavBarSubItem
                            key={`subitem-${i}-${index}`}
                            onClick={(event) =>
                              onClickMenuItem(event, subitem.data.button.uri)}
                          >
                            <Overpass htmlAttribute="span" type="subMenu">
                              {subitem.data.button.title}
                            </Overpass>
                          </NavBarSubItem>
                        ))}
                      </NavBarSubItemContainer>
                    )}
                  </NavBarItem>
                );
              })}
          </NavBarItems>
        </NavBarWrapper>
        <LinksContainer>
          {subscribeButtonVisible()
            && signup
            && Object.keys(signup).length > 0 && (
              <Button
                onClick={() => handleClickNavigation(signup.uri)}
                btnType={Button.TYPE.PRIMARY}
                btnText={signup.title}
              />
          )}
          {/* {signin && (
            <Link target="_blank" href={signin.uri}>
              <Overpass htmlAttribute="span" type="menuHeader">
                {signin.title}
              </Overpass>
            </Link>
          )} */}
          {!(subscribeButtonVisible() && signup) && (
            <Socials>
              {Object.keys(socials).map((i) => (
                <Icon
                  onClick={() => handleClickNavigation(socials[i].uri)}
                  size={30}
                  type={`icon-${socials[i].title}`}
                  key={`socials-${i}`}
                />
              ))}
            </Socials>
          )}
        </LinksContainer>
      </HeaderWrapper>
    </Header>
  );
};

NavBarDesktop.propTypes = {
  current_path: PropTypes.string.isRequired,
  menu: PropTypes.array,
  socials: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  signup: PropTypes.object,
  signin: PropTypes.object.isRequired,
  news: PropTypes.array
};

export default NavBarDesktop;
