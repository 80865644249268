import React from 'react';
import PropTypes from 'prop-types';
import Overpass from '../../ui/typography/overpass';
import {
  ContainerCollaboratorsCard,
  CardContainer,
  RowContainer,
  ImageProfile,
  ProfileContainer,
  Link,
  ImageProfilePhone,
  LinkPhone,
  LinkContainer,
  CardTitle,
  CardDecoration,
  CardDetails,
  LinkInPhone,
  BodyContainer
} from './style';
import { HtmlRaw } from '../../components';

const CollaboratorsCard = ({ data, type }) => {
  const { image, name, body, url, job_title } = data;

  return (
    <ContainerCollaboratorsCard>
      <CardContainer>
        <CardDetails cardType={type}>
          <RowContainer>
            <LinkInPhone>
              <ImageProfilePhone src={image?.url} alt="Profile photo" />
              <LinkPhone href={url?.uri} target="_blank">
                <Overpass type="cardLink">{url?.title}</Overpass>
              </LinkPhone>
            </LinkInPhone>
            <ImageProfile src={image?.url} alt="Profile photo" />
            <ProfileContainer>
              <CardTitle>
                <Overpass htmlAttribute="span" type="cardTitle">
                  {name}
                </Overpass>
              </CardTitle>
              <Overpass type="cardTask">
                <HtmlRaw html={job_title} />
              </Overpass>
            </ProfileContainer>
          </RowContainer>
          <LinkContainer>
            <Link href={url?.uri} target="_blank">
              <Overpass type="cardLink">{url?.title}</Overpass>
            </Link>
            <BodyContainer>
              <Overpass type="cardDescription" htmlAttribute="span">
                <HtmlRaw html={body} />
              </Overpass>
            </BodyContainer>
          </LinkContainer>
        </CardDetails>
        {type === CollaboratorsCard.TYPE.TERTIARY && <CardDecoration />}
      </CardContainer>
    </ContainerCollaboratorsCard>
  );
};

CollaboratorsCard.TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary'
};

CollaboratorsCard.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string
};

CollaboratorsCard.defaultProps = {
  type: CollaboratorsCard.TYPE.PRIMARY
};

export default CollaboratorsCard;
