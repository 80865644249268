import React from 'react';
import Slider from 'react-slick';
import { CarouselInterface } from '../../interface/carousel';
import { CarouselContainer } from './style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const default_settings = {
  dots: true,
  infinite: false,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Carousel = ({ settings = {}, children }) => {
  const slider_configuration = {
    ...default_settings,
    ...settings
  };

  return (
    <CarouselContainer>
      <Slider {...slider_configuration}>{children}</Slider>
    </CarouselContainer>
  );
};

Carousel.propTypes = {
  settings: CarouselInterface
};

export default Carousel;
