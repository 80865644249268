import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOADER_OPEN, LOADER_CLOSE } from '../actions';
import { GET_TESTIMONIALS } from '../actions/testimonials';
import aboutus from '../../api/aboutus';

function* getAboutUs() {
  try {
    yield put({ type: LOADER_OPEN });
    const { testimonials: testimonialsInfo } = yield select(
      state => state.testimonials
    );
    if (Object.keys(testimonialsInfo).length === 0) {
      const res = yield call(aboutus.getAboutUs);
      const { data: { data: testimonials = {} } = {} } = res;
      yield put({ type: GET_TESTIMONIALS._SUCCESS, testimonials });
    }
  } catch (error) {
    yield put({ type: GET_TESTIMONIALS._ERROR, error });
    console.log('sagas > aboutus > getAboutUs', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getAboutUsWatcher() {
  yield takeLatest(GET_TESTIMONIALS._REQUEST, getAboutUs);
}

export default [getAboutUsWatcher()];
