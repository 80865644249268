import React from 'react';
import PropTypes from 'prop-types';
import Typeface, { TypefaceInterface } from './index';
import { FontBase } from '../../const';
import theme from '../theme';

const baseConfig = {
  color: 'unset',
  fontFamily: theme.fontset.overpass,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 1,
  letterSpacing: 'unset',
  fontSize: FontBase
};

const types = {
  thin: {
    fontWeight: 100
  },
  thinItalic: {
    fontWeight: 100,
    fontStyle: 'italic'
  },
  extraLight: {
    fontWeight: 200
  },
  extraLightItalic: {
    fontWeight: 200,
    fontStyle: 'italic'
  },
  light: {
    fontWeight: 300
  },
  lightItalic: {
    fontWeight: 300,
    fontStyle: 'italic'
  },
  regularItalic: {
    fontStyle: 'italic'
  },
  semiBold: {
    fontWeight: 600
  },
  semiBoldItalic: {
    fontWeight: 600,
    fontStyle: 'italic'
  },
  bold: {
    fontWeight: 700
  },
  boldItalic: {
    fontWeight: 700,
    fontStyle: 'italic'
  },
  extraBold: {
    fontWeight: 800
  },
  extraBoldItalic: {
    fontWeight: 800,
    fontStyle: 'italic'
  },
  black: {
    fontWeight: 900
  },
  blackItalic: {
    fontWeight: 900,
    fontStyle: 'italic'
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 700
  },
  menuHeader: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.colors.primary.white
  },
  newsLabel: {
    fontSize: 10,
    fontWeight: 700,
    color: theme.colors.primary.darkGreen
  },
  newsTitle: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 600,
    color: theme.colors.primary.black5
  },
  newsDate: {
    fontSize: 10,
    fontWeight: 700,
    color: theme.colors.secondary.gray
  },
  detailIcon: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.colors.secondary.grayLight
  },
  detailText: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.colors.secondary.grayLight,
    textTransform: 'uppercase'
  },
  detailTextNumber: {
    fontSize: 30,
    fontWeight: 700,
    color: theme.colors.primary.green
  },
  partnerTitleDesktop: {
    fontSize: 36,
    fontWeight: 700,
    letterSpacing: '-2px',
    color: theme.colors.primary.black5
  },
  partnerTitleMobile: {
    fontSize: 28,
    fontWeight: 700,
    letterSpacing: '-2px',
    color: theme.colors.primary.black5
  },
  partnerTitleQuote: {
    fontSize: 28,
    fontWeight: 700,
    letterSpacing: '-1px',
    color: theme.colors.primary.black5
  },
  partnerTextQuote: {
    fontStyle: 'italic',
    fontSize: 30,
    color: theme.colors.secondary.grayDark,
    fontWeight: 300
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 1,
    color: theme.colors.secondary.grayLight
  },
  planBoxTitle: {
    fontSize: 42,
    fontWeight: 700,
    letterSpacing: -0.2,
    color: theme.colors.primary.white
  },
  planBoxTitleMobile: {
    fontSize: 40,
    fontWeight: 700,
    letterSpacing: -1,
    lineHeight: '50px',
    color: theme.colors.primary.white
  },
  planBoxText: {
    fontSize: 18,
    fontWeight: 400,
    color: theme.colors.primary.white,
    lineHeight: '30px'
  },
  planBoxLabel: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.colors.primary.white,
    textTransform: 'uppercase',
    letterSpacing: 1
  },
  headerTitle: {
    fontSize: 51,
    fontWeight: 700,
    color: theme.colors.primary.white,
    letterSpacing: '-2px'
  },
  headerTitleMobile: {
    fontSize: 40,
    fontWeight: 700,
    color: theme.colors.primary.white,
    letterSpacing: '-2px'
  },
  headerEdition: {
    fontSize: 14,
    color: theme.colors.primary.white,
    letterSpacing: 1
  },
  headerScroll: {
    fontSize: 14,
    color: theme.colors.primary.white
  },
  headerText: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '24px',
    letterSpacing: 0,
    color: theme.colors.primary.white
  },
  cardTitle: {
    fontSize: 28,
    fontWeight: 700,
    letterSpacing: '-1px',
    lineHeight: '36px',
    color: theme.colors.primary.black5
  },
  cardTask: {
    fontSize: 12,
    color: theme.colors.secondary.grayLight,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    lineHeight: 1.2,
    fontWeight: 700
  },
  cardDescription: {
    fontSize: 16,
    color: theme.colors.primary.black5,
    fontWeight: 300,
    lineHeight: '24px'
  },
  cardLink: {
    fontSize: 14,
    color: theme.colors.primary.teal,
    letterSpacing: '1px',
    lineHeight: '18px',
    fontWeight: 700
  },
  cardTestifier: {
    fontSize: 16,
    color: theme.colors.primary.black5,
    letterSpacing: '1px',
    fontWeight: 700,
    lineHeight: 1.5
  },
  subMenu: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.colors.primary.darkGreen
  },
  subMenuTablet: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.colors.primary.white
  },
  projectTitleColumn: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.colors.primary.darkGreen
  },
  projectTextColumn: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.colors.secondary.gray
  },
  text: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '24px',
    color: theme.colors.primary.black5
  },
  courseOnlineDetailTitle: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '26px',
    color: theme.colors.primary.black5
  },
  courseOnlineDetailBody: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '24px',
    color: theme.colors.secondary.gray
  },
  courseOnlineCardTitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.colors.primary.black5
  },
  courseOnlineCardBody: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '24px',
    color: theme.colors.secondary.gray
  },
  courseOnlineFooterTitle: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '44px',
    letterSpacing: '-2px',
    color: theme.colors.primary.black5
  },
  courseOnlineFooterBody: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '24px',
    letterSpacing: 0,
    color: theme.colors.primary.black5
  },
  sectionHeaderTitle: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '44px',
    letterSpacing: '-2px',
    color: theme.colors.primary.black5
  },
  sectionHeaderTitleMobile: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-1px',
    color: theme.colors.primary.black5
  },
  sectionHeaderBody: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '24px',
    color: theme.colors.secondary.black5
  },
  classroomCourseSubtitle: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-1px',
    color: theme.colors.primary.black5,
    textAlign: 'center'
  },
  classroomCourseSubtitlePhone: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-1px',
    color: theme.colors.primary.black5,
    textAlign: 'center'
  },
  authorName: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '1px',
    color: theme.colors.primary.black5
  },
  authorProfession: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '18px',
    letterSpacing: '1px',
    color: theme.colors.secondary.grayLighter
  },
  footerTitle: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    color: theme.colors.primary.white
  },
  footerText: {
    fontSize: 12,
    color: theme.colors.primary.white,
    lineHeight: '1.6'
  },
  partnerTitleCarousel: {
    fontSize: 28,
    fontWeight: 700,
    color: theme.colors.primary.black5,
    lineHeight: '34px',
    letterSpacing: '-1.27px'
  },
  viewMore: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.colors.primary.teal,
    letterSpacing: '1px'
  },
  emptyArrayMessage: {
    fontSize: 28,
    fontWeight: 700,
    color: theme.colors.secondary.grayLight,
    textAlign: 'center'
  }
};

const Overpass = ({
  type,
  configuration,
  children,
  htmlAttribute,
  onClick
}) => (
  <Typeface
    htmlAttribute={htmlAttribute}
    configuration={{ ...baseConfig, ...types[type], ...configuration }}
    onClick={onClick}
  >
    {children}
  </Typeface>
);

Overpass.propTypes = {
  htmlAttribute: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.keys(types)),
  configuration: TypefaceInterface,
  onClick: PropTypes.func
};

export default Overpass;
