import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Header,
  HeaderWrapper,
  MenuContainer,
  MenuList,
  MenuItem,
  ButtonsContainer,
  MenuSubItem,
  MenuItemContainer,
  Socials
} from './style';
import theme from '../../../ui/theme';
import { Button, Icon, MenuButton } from '../../../atoms';
import routes from '../../../routes';
import { NavBarHeight } from '../../../const';
import Overpass from '../../../ui/typography/overpass';
import { handleClickNavigation } from '../../../utils/common';

const NavBarTablet = ({
  current_path,
  menu,
  signup,
  signin,
  socials,
  mediaIsPhone,
  news
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [openSubMenu, setOpenSubMenu] = useState();

  useEffect(() => {
    const updateScroll = () => setScroll(window.scrollY);
    window.addEventListener('scroll', updateScroll);
  }, []);

  const isHomePage = current_path === routes.home.path;
  const transparent = isHomePage && scroll <= NavBarHeight && !menuIsOpen;

  const handleOnClickMenuItem = (menu_item, submenu = false) => {
    const {
      button: { uri, title },
      items
    } = menu_item;
    if ((!items && uri.length > 0) || submenu) {
      handleClickNavigation(uri);
      setMenuIsOpen(false);
      setOpenSubMenu('');
    } else {
      setOpenSubMenu(title);
    }
  };

  const onClickLogo = () => {
    handleClickNavigation(routes.home.path);
    setMenuIsOpen(false);
  };

  return (
    <Header transparent={transparent}>
      <HeaderWrapper>
        <Icon
          type="icon-logo-ps"
          size={40}
          color={theme.colors.primary.white}
          onClick={onClickLogo}
        />
        <MenuButton
          isOpen={menuIsOpen}
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        />
        <MenuContainer isOpen={menuIsOpen}>
          <MenuList>
            {menu
              && menu.map((item, i) => {
                if (item?.button?.uri === '/news' && !(news?.length > 0)) {
                  return null;
                }
                return (
                  <MenuItemContainer key={`MenuItemContainer-${i}`}>
                    <MenuItem onClick={() => handleOnClickMenuItem(item)}>
                      <Overpass htmlAttribute="span" type="menuHeader">
                        {item.button.title}
                      </Overpass>
                    </MenuItem>
                    {!!item.items
                      && openSubMenu === item.button.title
                      && [...item.items].map((subitem, index) => (
                        <MenuSubItem
                          key={`subitem-${i}-${index}`}
                          onClick={() =>
                            handleOnClickMenuItem(subitem.data, true)}
                        >
                          <Overpass htmlAttribute="span" type="subMenuTablet">
                            {subitem.data.button.title}
                          </Overpass>
                        </MenuSubItem>
                      ))}
                  </MenuItemContainer>
                );
              })}
          </MenuList>
          <ButtonsContainer>
            {socials && (
              <Socials>
                {Object.keys(socials).map((i) => (
                  <Icon
                    onClick={() => handleClickNavigation(socials[i].uri)}
                    size={30}
                    type={`icon-${socials[i].title}`}
                    key={`socials-${i}`}
                  />
                ))}
              </Socials>
            )}
            {signup && Object.keys(signup).length > 0 && (
              <Button
                onClick={() => handleClickNavigation(signup.uri)}
                minHeight={40}
                minWidth={mediaIsPhone ? 260 : 552}
                btnType={Button.TYPE.PRIMARY}
                btnText={signup.title}
              />
            )}
            {/* {signin && (
              <Link target="_blank" href={signin.uri}>
                <Overpass type="menuHeader" htmlAttribute="span">
                  {signin.title}
                </Overpass>
              </Link>
            )} */}
          </ButtonsContainer>
        </MenuContainer>
      </HeaderWrapper>
    </Header>
  );
};

NavBarTablet.propTypes = {
  current_path: PropTypes.string.isRequired,
  menu: PropTypes.array,
  signup: PropTypes.object,
  signin: PropTypes.object.isRequired,
  socials: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  mediaIsPhone: PropTypes.bool.isRequired,
  news: PropTypes.array
};

export default NavBarTablet;
