import { call, put, takeLatest, select } from 'redux-saga/effects';
import { LOADER_OPEN, LOADER_CLOSE } from '../actions';
import { GET_COURSE_ONLINE, GET_COURSE_CLASSROOM } from '../actions/plan';
import plan from '../../api/plan';

function* getCourseOnline() {
  try {
    yield put({ type: LOADER_OPEN });
    const { course_online: courseonlineInfo } = yield select(
      state => state.plan
    );
    if (Object.keys(courseonlineInfo).length === 0) {
      const res = yield call(plan.getCourseOnline);
      const { data: { data: course_online = {} } = {} } = res;
      yield put({ type: GET_COURSE_ONLINE._SUCCESS, course_online });
    }
  } catch (error) {
    yield put({ type: GET_COURSE_ONLINE._ERROR, error });
    console.log('sagas > plan > getCourseOnline', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCourseOnlineWatcher() {
  yield takeLatest(GET_COURSE_ONLINE._REQUEST, getCourseOnline);
}

function* getCourseClassroom() {
  try {
    yield put({ type: LOADER_OPEN });
    const { course_classroom: classroomInfo } = yield select(
      state => state.plan
    );
    if (Object.keys(classroomInfo).length === 0) {
      const res = yield call(plan.getCourseClassroom);
      const { data: { data: course_classroom = {} } = {} } = res;
      yield put({ type: GET_COURSE_CLASSROOM._SUCCESS, course_classroom });
    }
  } catch (error) {
    yield put({ type: GET_COURSE_CLASSROOM._ERROR, error });
    console.log('sagas > plan > getCourseClassroom', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCourseClassroomWatcher() {
  yield takeLatest(GET_COURSE_CLASSROOM._REQUEST, getCourseClassroom);
}

export default [getCourseOnlineWatcher(), getCourseClassroomWatcher()];
