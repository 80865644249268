import app from './app';
import home from './home';
import news from './news';
import partners from './partners';
import plan from './plan';
import testimonials from './testimonials';
import project from './project';
import modal from './modal';
import faq from './faq';

export default {
  app,
  home,
  news,
  partners,
  plan,
  testimonials,
  project,
  modal,
  faq
};
