import React from 'react';

import PropTypes from 'prop-types';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { Carousel } from '../index';
import { NewsCard } from '../../atoms';
import { RecentNewsContainer } from './style';

const RecentNews = ({ news = [] }) => {
  const slider_configuration = {
    autoplay: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    autoplaySpeed: 3000
  };

  return (
    <RecentNewsContainer>
      <Carousel settings={slider_configuration}>
        {[...news].splice(0, 5).map((item, i) => (
          <NewsCard news={item} key={`RecentNews-${i}`} isRecent />
        ))}
      </Carousel>
    </RecentNewsContainer>
  );
};

RecentNews.propTypes = {
  news: PropTypes.array.isRequired
};

export default withMediaQueries(RecentNews);
