import React from 'react';
import PropTypes from 'prop-types';
import Overpass from '../../ui/typography/overpass';
import {
  TestimonialsCardContainer,
  CardContainer,
  ImageProfile,
  CommentContainer,
  BodyContainer,
  CardDecoration,
  CardDetails
} from './style';
import { HtmlRaw } from '../../components';

const TestimonialsCard = ({ data, type }) => {
  const { image, personal_data, job_title, body } = data;

  return (
    <TestimonialsCardContainer>
      <CardContainer>
        <CardDetails cardType={type}>
          <ImageProfile src={image?.url} alt="Profile photo" />
          <BodyContainer>
            <CommentContainer>
              <Overpass type="cardDescription">
                <HtmlRaw html={body} />
              </Overpass>
            </CommentContainer>
            <Overpass type="cardTestifier">{personal_data}</Overpass>
            <Overpass type="cardTask">{job_title}</Overpass>
          </BodyContainer>
        </CardDetails>
        {type === TestimonialsCard.TYPE.TERTIARY && <CardDecoration />}
      </CardContainer>
    </TestimonialsCardContainer>
  );
};

TestimonialsCard.TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary'
};

TestimonialsCard.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string
};

TestimonialsCard.defaultProps = {
  type: TestimonialsCard.TYPE.PRIMARY
};

export default TestimonialsCard;
