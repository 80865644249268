import styled from 'styled-components';
import { Col, Row } from '../../ui/gridSystem';

export const ColDetailPartner = styled(Col)`
  margin-bottom: 40px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const RowDetailsIconsContainer = styled(Row)`
  width: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  .section-title {
    margin-bottom: 28px;
  }

  > div {
    &:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &:nth-child(2) {
      padding-top: 36px;

      @media ${props => props.theme.device.mobile} {
        padding-top: 10px;
      }
    }
  }
`;
