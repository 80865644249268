import produce from 'immer';

import { MODAL_CLOSE, MODAL_OPEN } from '../actions';

const initialState = {
  id: '',
  open: false,
  data: undefined
};

const modalReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case MODAL_CLOSE: {
        draft = initialState;
        break;
      }

      case MODAL_OPEN: {
        if (action.id) {
          draft.id = action.id;
          draft.data = action.data;
          draft.open = true;
        }

        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default modalReducer;
