import styled from 'styled-components';

export const PartnerCarouselWrapper = styled.div`
  position: relative;
  & > span {
    position: absolute;
    top: 55px;
    &:last-child {
      right: 0;
    }
  }
`;

export const PartnerCarouselContainer = styled.section`
  padding: 25px 0 50px;
  .slick-slider {
    span {
      &.icon {
        &:first-of-type {
          position: absolute;
          left: -30px;
          top: 50%;
          margin-top: -15px;
        }
        &:nth-of-type(2) {
          position: absolute;
          right: -30px;
          top: 50%;
          margin-top: -15px;
        }
      }
    }

    .slick-slide {
      > div {
        display: flex;
      }
    }
  }
`;

export const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ContainerLogo = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 100px;

    img {
      align-self: center;
      max-width: 300px;

      @media ${props => props.theme.device.mobile} {
          max-width: 148px;
      }
    }
`;
