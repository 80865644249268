import produce from 'immer';
import { GET_FAQ } from '../actions/faq';

const initialState = {
  faq: {}
};

const faqReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_FAQ._SUCCESS: {
        const { faq } = action;
        draft.faq = { ...faq };
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default faqReducer;
