import { apiCall, baseUrl, shouldUseMock } from './config';
import { navbarMock } from './mock';

export default {
  getNavbar: () => {
    if (shouldUseMock()) {
      return navbarMock();
    }
    return apiCall(`${baseUrl}bootstrap`, 'GET');
  }
};
