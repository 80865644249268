import styled from 'styled-components';
import { Col, Row } from '../../ui/gridSystem';

export const ContainerQuote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  min-height: 650px;
  height: 650px;
`;

export const Image = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: url(${props => props.src});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 40px 0 30px;
`;

export const QuoteImage = styled.div``;

export const ColQuoteText = styled(Col)``;

export const PeopleContainer = styled.div`
  margin-top: 30px;

  div {
    + div {
      margin-top: 10px;
    }
  }
`;

export const ColQuoteIcon = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    (props.type === 'open' ? 'flex-start' : 'flex-end')};
  margin-top: ${props => (props.type === 'open' ? '-20px' : '0')};
  @media ${props => props.theme.device.mobile} {
    flex-direction: row;
    margin-bottom: ${props => (props.type === 'open' ? '10px' : '0')};
    margin-top: 0;
  }
`;

export const RowButton = styled(Row)`
  margin: 0 auto;
  margin-top: 67px;
`;
