import './ui/scss/bootstrap/bootstrap-grid.min.css';
import './ui/scss/app.scss';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import theme from './ui/theme';
import { history } from './redux/store';
import routes from './routes';
import { GET_NAVBAR } from './redux/actions/navbar';

import { GlobalLayout, Loader } from './components';
import ModalsPortal from './components/Modals/ModalsPortal';
/* Pages */
import {
  Sample,
  Home,
  News,
  NewsDetail,
  Partners,
  Project,
  Faq,
  Testimonials
} from './pages';
import { GET_NEWS } from './redux/actions/news';

const App = ({ getNavbar, getNews }) => {
  useEffect(() => {
    getNavbar();
    getNews({ categoryId: null, date: null });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Loader />
        <GlobalLayout>
          <Switch>
            <Route path={routes.home.path} exact render={() => <Home />} />
            <Route
              path={routes.testimonials.path}
              render={() => <Testimonials />}
            />
            <Route
              path={`${routes.news.path}/:id`}
              exact
              render={({ match: { params: { id = '' } = {} } = {} }) => (
                <NewsDetail news_id={id} />
              )}
            />
            <Route path={routes.news.path} render={() => <News />} />
            <Route path={routes.partners.path} exact render={() => <Partners />} />
            {/* <Route
              path={`${routes.partners.path}/:id`}
              exact
              render={({ match: { params: { id = '' } = {} } = {} }) => (
                <PartnerDetail partner_id={id} />
              )}
            /> */}
            {/* <Route
              path={routes.onlineCourse.path}
              render={() => <OnlineCourse />}
            />
            <Route
              path={routes.classroomCourse.path}
              render={() => <ClassroomCourse />}
            /> */}
            <Route path={routes.project.path} render={() => <Project />} />
            <Route path={routes.sample.path} render={() => <Sample />} />
            <Route path={routes.faq.path} render={() => <Faq />} />
            <Redirect to={routes.home.path} />
          </Switch>
        </GlobalLayout>
      </Router>
      <ModalsPortal.Target />
    </ThemeProvider>
  );
};

App.propTypes = {
  // HOC (connect, dispatch)
  getNavbar: PropTypes.func.isRequired,
  getNews: PropTypes.func.isRequired
};

export default connect(
  () => ({}),
  dispatch => ({
    getNavbar: () => dispatch({ type: GET_NAVBAR._REQUEST }),
    getNews: (filters) => dispatch({ type: GET_NEWS._REQUEST, filters })
  })
)(App);
