import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import {
  PartnerDetailEditorialContent,
  PartnerDetailsBodyItem,
  PartnerDetailContainer
} from './style';
import { GET_PARTNER_DETAIL, GET_PARTNERS } from '../../redux/actions/partners';
import render_body from '../../utils/render_body';
import { goBack } from '../../utils/common';
import routes from '../../routes';
import { PartnerCarousel } from '../../components';
import { SectionTitle } from '../../atoms';

const PartnerDetail = ({
  partner_id,
  getPartnerDetail,
  getPartners,
  partnerdetail,
  partners
}) => {
  useEffect(() => {
    getPartnerDetail(partner_id);
    getPartners();
  }, [getPartnerDetail, getPartners, partner_id]);

  return (
    <>
      <Helmet>
        <title>Palestre sostenibili - Partner details</title>
      </Helmet>
      <PartnerDetailContainer>
        <SectionTitle
          title={`/   ${partnerdetail.name}`}
          back="PARTNER"
          onClickBack={() => goBack(routes.partners.path)}
        />
        <PartnerDetailEditorialContent>
          {partnerdetail.page_items && (
          <>
            {[...partnerdetail.page_items].map((item, i) => (
              <PartnerDetailsBodyItem
                className={`item-${item.type}`}
                key={`PartnerDetailsBodyItem-${i}`}
              >
                {render_body(item)}
              </PartnerDetailsBodyItem>
            ))}
          </>
          )}
        </PartnerDetailEditorialContent>
        <PartnerCarousel partners={partners} />
      </PartnerDetailContainer>
    </>
  );
};

PartnerDetail.propTypes = {
  partner_id: PropTypes.string.isRequired,

  // HOC (connect, state)
  partnerdetail: PropTypes.object.isRequired,
  partners: PropTypes.array,
  // HOC (connect, dispatch)
  getPartners: PropTypes.func.isRequired,
  getPartnerDetail: PropTypes.func.isRequired
};

export default connect(
  state => {
    const {
      partners: { partner_items = [] },
      partnerdetail
    } = state.partners;

    return {
      partnerdetail,
      partners: partner_items
    };
  },
  dispatch => ({
    getPartnerDetail: partnerId =>
      dispatch({ type: GET_PARTNER_DETAIL._REQUEST, partnerId }),
    getPartners: () => dispatch({ type: GET_PARTNERS._REQUEST })
  })
)(withMediaQueries(PartnerDetail));
