import React from 'react';
import PropTypes from 'prop-types';
import Overpass from '../../ui/typography/overpass';
import { ContainerDetailText } from './style';
import { HtmlRaw } from '../../components';

const DetailText = ({ text, number }) => (
  <ContainerDetailText>
    <Overpass type="detailTextNumber">{number}</Overpass>
    <Overpass type="detailText">
      <HtmlRaw html={text} />
    </Overpass>
  </ContainerDetailText>
);

DetailText.propTypes = {
  text: PropTypes.string,
  number: PropTypes.string
};

export default DetailText;
