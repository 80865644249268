import styled from 'styled-components';

export const FaqContainer = styled.section`
  margin-top: 50px;

  .section-title {
    margin-bottom: 20px;
  }
`;

export const FaqEditorialContent = styled.section`
  margin: 50px 0 25px;
`;

export const FaqsBodyItem = styled.div`
  &.item {
    &-article {
      margin-bottom: 91px;
    }
    &-images {
      margin-bottom: 80px;
    }
    &-file_article {
      margin-bottom: 80px;
    }
    &-item-witnesses {
      margin-bottom: 50px;
    }
    &-title_articles {
      margin-bottom: 86px;
    }
    &-faq_item {
      margin-bottom: 50px;
      @media ${props => props.theme.device.tablet} {
        margin-left: 20px;
      }
      @media ${props => props.theme.device.desktop} {
        margin-left: 40px;
      }
    }
  }
`;
