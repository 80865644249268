import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FooterWrapper,
  FooterRow,
  ContainerLogo,
  ContainerText,
  ColumnText,
  Socials,
  BoxText
} from './style';
import Overpass from '../../ui/typography/overpass';
import theme from '../../ui/theme';
import { Icon } from '../../atoms';
import { Row } from '../../ui/gridSystem';

import { withMediaQueries } from '../../hoc/withMediaQueries';
import { handleClickNavigation } from '../../utils/common';
import routes from '../../routes';
import HtmlRaw from '../HtmlRaw';

const Footer = ({ navbar, mediaIsPhone, mediaIsTablet }) => {
  const { social_buttons: socials = {} } = navbar;
  const { footer_promoted_by } = navbar;
  const { footer_promoted_by_partner = [] } = navbar;
  const { footer_padronage } = navbar;
  const { footer_padronage_partner = [] } = navbar;
  const { footer_collaboration_with } = navbar;
  const { footer_collaboration_with_partner = [] } = navbar;
  const { footer_collaboration_with_us } = navbar;
  const { footer_collaboration_with_us_detail } = navbar;

  const logoSize = mediaIsPhone || mediaIsTablet ? 40 : 50;

  return (
    <FooterWrapper>
      <FooterRow className={mediaIsPhone && 'no-gutters'}>
        <ContainerLogo lg={3} md={12} sm={12}>
          <Icon
            onClick={() => handleClickNavigation(routes.home.path)}
            type="icon-logo-ps"
            size={logoSize}
            color={theme.colors.primary.white}
          />
        </ContainerLogo>
        <ContainerText lg={9} md={12}>
          <Row>
            {(footer_promoted_by || footer_promoted_by_partner) && (
              <ColumnText
                lg={{ size: 3, offset: 1 }}
                md={{ size: 3, offset: 1 }}
                sm={{ size: 6, offset: 0 }}
              >
                <BoxText>
                  {footer_promoted_by && (
                    <Overpass htmlAttribute="span" type="footerTitle">
                      {footer_promoted_by}
                    </Overpass>
                  )}
                  {footer_promoted_by_partner.map(({ name }, i) => (
                    <Overpass key={i} type="footerText">
                      {name}
                    </Overpass>
                  ))}
                </BoxText>
                <BoxText>
                  {footer_padronage && (
                    <Overpass htmlAttribute="span" type="footerTitle">
                      {footer_padronage}
                    </Overpass>
                  )}
                  {footer_padronage_partner.map(({ name }, i) => (
                    <Overpass key={i} type="footerText">
                      {name}
                    </Overpass>
                  ))}
                </BoxText>
              </ColumnText>
            )}
            {(footer_collaboration_with
              || footer_collaboration_with_partner) && (
              <ColumnText
                lg={{ size: 3, offset: 1 }}
                md={{ size: 3, offset: 1 }}
                sm={{ size: 6, offset: 0 }}
              >
                <BoxText>
                  {footer_collaboration_with && (
                    <Overpass htmlAttribute="span" type="footerTitle">
                      {footer_collaboration_with}
                    </Overpass>
                  )}
                  {footer_collaboration_with_partner.map(({ name }, i) => (
                    <Overpass key={i} type="footerText">
                      {name}
                    </Overpass>
                  ))}
                </BoxText>
              </ColumnText>
            )}
            {(footer_collaboration_with_us
              || footer_collaboration_with_us_detail) && (
              <ColumnText
                lg={{ size: 3, offset: 1 }}
                md={{ size: 3, offset: 1 }}
                sm={{ size: 6, offset: 0 }}
              >
                <BoxText>
                  {footer_collaboration_with_us && (
                    <Overpass htmlAttribute="span" type="footerTitle">
                      {footer_collaboration_with_us}
                    </Overpass>
                  )}
                  {footer_collaboration_with_us_detail && (
                    <Overpass type="footerText">
                      <HtmlRaw html={footer_collaboration_with_us_detail} />
                    </Overpass>
                  )}
                </BoxText>
                <BoxText>
                  <Socials>
                    {Object.keys(socials).map(i => (
                      <Icon
                        onClick={() => handleClickNavigation(socials[i].uri)}
                        size={30}
                        type={`icon-${socials[i].title}`}
                        key={`socials-${i}`}
                        color={theme.colors.primary.white}
                      />
                    ))}
                  </Socials>
                </BoxText>
              </ColumnText>
            )}
          </Row>
        </ContainerText>
      </FooterRow>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,
  // HOC (connect, state)
  navbar: PropTypes.object
};

export default connect(state => {
  const { navbar } = state.app;
  return {
    navbar
  };
})(withMediaQueries(Footer));
