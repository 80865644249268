import React from 'react';

import PropTypes from 'prop-types';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { RowImagesContainer } from './style';
import Col from '../../ui/gridSystem/Col';
import Image from '../../atoms/Image';
import { ImageInterface } from '../../interface/image';

const RowImages = ({ mediaIsPhone, configuration = [], images = [] }) => {
  const column = () => {
    switch (images.length) {
      case 2:
        return 6;
      case 1:
        return 12;
      default:
        return 4;
    }
  };

  return (
    <RowImagesContainer className="no-gutters">
      {[...images].splice(0, mediaIsPhone ? 1 : 3).map((image, index) => (
        <Col md={column()} key={`partner-image-${index}`}>
          <Image
            imageUrl={image.url}
            x={configuration[index]?.x}
            y={configuration[index]?.y}
            position={configuration[index]?.position}
            lines={configuration[index]?.lines}
            size={configuration[index]?.size}
            className={images?.length === 1 && 'single-image'}
          />
        </Col>
      ))}
    </RowImagesContainer>
  );
};

RowImages.propTypes = {
  images: PropTypes.arrayOf(ImageInterface),
  configuration: PropTypes.array,
  mediaIsPhone: PropTypes.bool
};

export default withMediaQueries(RowImages);
