import styled from 'styled-components';

export const ContainerAboutUs = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;

  .section-title {
    margin-bottom: 18px;
  }
`;
export const Article = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: 80px;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 30px;
  }
  .col-md-12:first-child {
    @media ${props => props.theme.device.tablet} {
      margin-bottom: 45px;
    }
  }
  @media ${props => props.theme.device.mobile} {
    margin-bottom: 32px;
    margin-left: 0px;
  }
  .col-md-12:first-child {
    @media ${props => props.theme.device.mobile} {
      margin-bottom: 40px;
    }
  }
`;
export const CentralImage = styled.div`
  width: 100%;
  margin-bottom: 40px;
  max-height: 290px;
  height: 100%;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 100px;
  }

  @media ${props => props.theme.device.mobile} {
    margin-bottom: 70px;
  }
`;

export const CardComponent = styled.div`
  margin-bottom: 80px;
`;

export const Title = styled.div``;
