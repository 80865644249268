import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { withMediaQueries } from '../../hoc/withMediaQueries';

import {
  CourseOnlineContainer,
  Article,
  Partners,
  PartnersImages,
  CourseDetailList,
  CourseDetailItem,
  CentralImage,
  CardsList,
  CardItem,
  PageFooter,
  PageFooterTitle,
  PageFooterBody
} from './style';
import {
  Image,
  SectionTitle,
  CourseOnlineDetail,
  CourseOnlineCard
} from '../../atoms';
import Col from '../../ui/gridSystem/Col';
import { HtmlRaw, RowImages, PageArticle } from '../../components';
import Overpass from '../../ui/typography/overpass';
import Row from '../../ui/gridSystem/Row';
import { GET_COURSE_ONLINE } from '../../redux/actions/plan';
import { composeDefaultButton } from '../../utils/common';
import RowDetailsIcons from '../../components/RowDetailsIcons';

const CourseOnline = ({
  course_online,
  getCourseOnline,
  mediaIsPhone,
  mediaIsTablet
}) => {
  useEffect(() => {
    getCourseOnline();
  }, [getCourseOnline]);

  const course_info = Object.keys(course_online).reduce((acc, key) => {
    if (!course_online[key] || course_online[key]?.length <= 0) return { ...acc };
    switch (key) {
      case 'initial_article':
      case 'central_article':
      case 'title_articles_button_box':
      case 'images_list':
      case 'ending_article':
        return {
          ...acc,
          [key]: course_online[key][0].data
        };
      case 'main_articles':
      case 'image_article_box':
        return {
          ...acc,
          [key]: course_online[key].map(item => item.data)
        };
      default:
        return {
          ...acc,
          [key]: course_online[key]
        };
    }
  }, {});

  const {
    title,
    initial_article,
    main_articles,
    central_image,
    central_article,
    image_article_box,
    title_articles_button_box,
    images_list,
    ending_article
  } = course_info;

  const formatted_image_article_box = (image_article_box || []).reduce(
    (acc, val) => [
      ...acc,
      {
        ...val.article[0].data,
        ...val.file[0].data.image
      }
    ],
    []
  );

  const PartnerRowImagesConfig = [
    {
      x: Image.X.LEFT,
      y: Image.Y.BOTTOM,
      position: Image.POSITION.OUTSIDE,
      size: Image.SIZE.BIG
    },
    {
      lines: false
    },
    {
      x: Image.X.RIGHT,
      y: Image.Y.TOP,
      position: Image.POSITION.INSIDE
    }
  ];

  const CentralImageConfig = {
    x: Image.X.LEFT,
    y: Image.Y.BOTTOM,
    position: Image.POSITION.OUTSIDE,
    size: Image.SIZE.SMALL
  };

  return (
    <>
      <Helmet>
        <title>Palestre sostenibili - Online course</title>
      </Helmet>
      <CourseOnlineContainer>
        {title && <SectionTitle title={title} />}
        {(initial_article?.title || initial_article?.body) && (
        <Article>
          <PageArticle
            title={initial_article?.title}
            body={initial_article?.body}
          />
        </Article>
        )}
        {main_articles && (
        <CourseDetailList>
          {[...main_articles].map((article, index) => (
            <CourseDetailItem
              key={`CourseDetailItem-${index}`}
              lg={6}
              md={6}
              sm={12}
            >
              <CourseOnlineDetail title={article.title} body={article.body} />
            </CourseDetailItem>
          ))}
        </CourseDetailList>
        )}
        {central_image?.url && (
        <CentralImage>
          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              className={mediaIsPhone || mediaIsTablet ? 'p-0' : ''}
            >
              <Image
                imageUrl={central_image?.url}
                lines={!(mediaIsPhone || mediaIsTablet)}
                {...CentralImageConfig}
              />
            </Col>
          </Row>
        </CentralImage>
        )}
        {(central_article?.title || central_article?.body) && (
        <Article className="central-article">
          <PageArticle
            title={central_article?.title}
            body={central_article?.body}
          />
        </Article>
        )}
        {formatted_image_article_box && (
        <CardsList>
          {[...formatted_image_article_box].map((item, index) => (
            <CardItem key={`CourseOnlineCard-${index}`}>
              <CourseOnlineCard card={item} />
            </CardItem>
          ))}
        </CardsList>
        )}
        {(title_articles_button_box?.articles
        || title_articles_button_box?.title) && (
        <Partners>
          <RowDetailsIcons
            text={title_articles_button_box?.title}
            button={composeDefaultButton(
              title_articles_button_box?.button?.title,
              title_articles_button_box?.button?.uri
            )}
            content={title_articles_button_box?.articles}
          />
        </Partners>
        )}
        {images_list && (
        <PartnersImages>
          <RowImages
            images={images_list.images}
            configuration={PartnerRowImagesConfig}
          />
        </PartnersImages>
        )}
        {(ending_article?.title || ending_article?.body) && (
        <PageFooter>
          <PageFooterTitle>
            <Overpass htmlAttribute="span" type="courseOnlineFooterTitle">
              {ending_article?.title}
            </Overpass>
          </PageFooterTitle>
          <PageFooterBody>
            <Overpass htmlAttribute="span" type="courseOnlineFooterBody">
              <HtmlRaw html={ending_article?.body} />
            </Overpass>
          </PageFooterBody>
        </PageFooter>
        )}
      </CourseOnlineContainer>
    </>
  );
};

CourseOnline.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,

  // HOC (connect, state)
  course_online: PropTypes.object.isRequired,

  // HOC (connect, dispatch)
  getCourseOnline: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { course_online } = state.plan;
    return {
      course_online
    };
  },
  dispatch => ({
    getCourseOnline: () => dispatch({ type: GET_COURSE_ONLINE._REQUEST })
  })
)(withMediaQueries(CourseOnline));
