import styled from 'styled-components';
import { NavBarHeight } from '../../const';

export const PageContainer = styled.div`
  padding-top: ${NavBarHeight}px;
  min-height: 100vh;
  overflow-x: hidden;
`;

export const PageContent = styled.div`
  @media (min-width: 1200px) {
    margin: 0 -20px;
  }
`;
