import { apiCall, baseUrl, shouldUseMock } from './config';
import { faqMock } from './mock';

export default {
  getFaq: () => {
    if (shouldUseMock()) {
      return faqMock();
    }
    // TODO: check url
    return apiCall(`${baseUrl}faq`, 'GET');
  }
};
