import { apiCall, baseUrl, shouldUseMock } from './config';
import { courseclassroomMock, courseonlineMock } from './mock';

const extendedUrl = 'course/';

export default {
  getCourseOnline: () => {
    if (shouldUseMock()) {
      return courseonlineMock();
    }
    return apiCall(`${baseUrl}${extendedUrl}online`, 'GET');
  },
  getCourseClassroom: () => {
    if (shouldUseMock()) {
      return courseclassroomMock();
    }
    return apiCall(`${baseUrl}${extendedUrl}classroom`, 'GET');
  }
};
