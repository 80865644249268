import axios from 'axios';

export const shouldUseMock = () =>
  process.env.REACT_APP_DEPLOY_ENV?.trim() === 'LOCAL'
  && Number(process.env.REACT_APP_ENABLE_MOCK) === 1;

const FE_BASE_URL = {
  LOCAL: 'http://localhost',
  DEV: 'https://stage-fe.palestresostenibili.it',
  PROD: 'https://palestresostenibili.it',
  DEFAULT: 'https://palestresostenibili.it'
};
export const feBaseUrl = FE_BASE_URL[process.env.REACT_APP_DEPLOY_ENV] || FE_BASE_URL.DEFAULT;

const BASIC_AUTH_TOKENS = {
  LOCAL: 'YXBpX3VzZXI6YXBpX3VzZXI=',
  DEV: 'YXBpX3VzZXI6YXBpX3VzZXI=',
  PROD: 'YXBpX3VzZXI6YXBpX3VzZXI='
};

const ENV_BASE_URL = {
  LOCAL: 'http://api.palestresostenibili.local',
  DEV: 'https://stage-be.palestresostenibili.it',
  PROD: 'https://be.palestresostenibili.it',

  DEFAULT: `${window.location.origin}/api`
};

export const apiHost = ENV_BASE_URL[process.env.REACT_APP_DEPLOY_ENV] || ENV_BASE_URL.DEFAULT;

const basiAuthToken = BASIC_AUTH_TOKENS[process.env.REACT_APP_DEPLOY_ENV] || '';

export const baseUrl = '/api/';

const axiosApi = axios.create({
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosApi.interceptors.request.use((req) => {
  req.headers.Authorization = `Basic ${basiAuthToken}`;
  return req;
});

axiosApi.interceptors.response.use(
  (res) => res,
  (error) => error
);

export function apiCall(url, method, body = null, options = null) {
  return axiosApi[method.toLowerCase()](`${apiHost}${url}`, body, options);
}
