const ENV_BASE_PATH = {
  DEV: null,
  PRE_PROD: null,
  PROD: null,

  DEFAULT: '/'
};
const APP_DEPLOY_ENV = process.env.REACT_APP_DEPLOY_ENV
  ? process.env.REACT_APP_DEPLOY_ENV.trim()
  : process.env.REACT_APP_DEPLOY_ENV;
const basePath = ENV_BASE_PATH[APP_DEPLOY_ENV] || ENV_BASE_PATH.DEFAULT;

const baseRoutes = {
  home: {
    path: `${basePath}`,
    title: 'Palestre sostenibili',
    label: 'home'
  },
  testimonials: {
    path: `${basePath}aboutus`,
    title: 'Testimonials',
    label: 'testimonials'
  },
  news: {
    path: `${basePath}news`,
    title: 'News',
    label: 'news'
  },
  partners: {
    path: `${basePath}aziende-partner`,
    title: 'Partners',
    label: 'partners'
  },
  onlineCourse: {
    path: `${basePath}course/online`,
    title: 'Online Course',
    label: 'corsi online'
  },
  classroomCourse: {
    path: `${basePath}course/classroom`,
    title: 'Classroom Course',
    label: 'aula'
  },
  project: {
    path: `${basePath}corso-palestre-sostenibili`,
    title: 'Project',
    label: 'project'
  },
  sample: {
    path: `${basePath}sample`,
    title: 'Sample',
    label: 'sample'
  },
  faq: {
    path: `${basePath}faq-domande-frequenti`,
    title: 'Faq',
    label: 'faq'
  }
};

const routes = {
  ...baseRoutes
};

export default routes;
