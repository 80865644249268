import React from 'react';
import PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalOverLay } from '../../atoms';
import { PageContainer, PageContent } from './styles';

import { Container } from '../../ui/gridSystem';
import NavBar from '../NavBar';
import Footer from '../Footer';

const GlobalLayout = ({ children }) => (
  <HelmetProvider>
    <NavBar />
    <PageContainer>
      <GlobalOverLay />
      <Container style={{ position: 'relative' }}>
        <PageContent className="page-content">{children}</PageContent>
      </Container>
    </PageContainer>
    <Footer />
  </HelmetProvider>
);

GlobalLayout.propTypes = {
  children: PropTypes.node
};

export default GlobalLayout;
