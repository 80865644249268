import React from 'react';

import PropTypes from 'prop-types';
import { Icon } from '../../atoms';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import {
  PartnerCarouselContainer,
  Slide,
  ContainerLogo,
  PartnerCarouselWrapper
} from './style';
import Carousel from '../Carousel';
import { Logo } from '../../pages/Partners/style';
import theme from '../../ui/theme';
import { handleClickNavigation } from '../../utils/common';
import routes from '../../routes';

const PartnerCarousel = ({ mediaIsPhone, mediaIsTablet, partners }) => {
  const setNumber = (number) =>
    (partners.length >= number ? number : partners.length);

  const logosCarouselConfig = {
    slidesToShow: mediaIsPhone || mediaIsTablet ? 1 : setNumber(6),
    slidesToScroll: mediaIsPhone || mediaIsTablet ? 1 : setNumber(6),
    rows: mediaIsPhone ? 3 : mediaIsTablet ? setNumber(2) : 1,
    slidesPerRow: mediaIsPhone ? 2 : mediaIsTablet ? setNumber(3) : 1,
    dots: partners.length > 6,
    arrows: !(mediaIsPhone || mediaIsTablet),
    infinite: partners.length >= 6,
    prevArrow: partners.length > 6 && (
      <Icon
        type="icon-chevron-dx"
        size={30}
        color={theme.colors.primary.teal}
      />
    ),
    nextArrow: partners.length > 6 && (
      <Icon
        type="icon-chevron-sx"
        size={30}
        color={theme.colors.primary.teal}
      />
    )
  };
  return (
    <PartnerCarouselWrapper style={{ position: 'relative' }}>
      {partners?.length === 1 && !(mediaIsPhone || mediaIsTablet) && (
        <Icon
          type="icon-chevron-dx"
          size={30}
          color={theme.colors.primary.teal}
        />
      )}
      <PartnerCarouselContainer>
        <Carousel settings={logosCarouselConfig}>
          {partners.map((logo, i) => (
            <Slide key={i}>
              <ContainerLogo>
                <Logo src={logo.image.url} itemsNumber={partners?.length || 1} />
              </ContainerLogo>
            </Slide>
          ))}
        </Carousel>
      </PartnerCarouselContainer>
      {partners?.length === 1 && !(mediaIsPhone || mediaIsTablet) && (
        <Icon
          type="icon-chevron-sx"
          size={30}
          color={theme.colors.primary.teal}
        />
      )}
    </PartnerCarouselWrapper>
  );
};

PartnerCarousel.propTypes = {
  partners: PropTypes.array,

  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired
};

export default withMediaQueries(PartnerCarousel);
