import { call, put, takeLatest, select } from 'redux-saga/effects';
import { GET_PROJECT } from '../actions/project';
import projectpage from '../../api/project';
import { LOADER_OPEN, LOADER_CLOSE } from '../actions';

function* getProjectPage() {
  try {
    yield put({ type: LOADER_OPEN });
    const { project: projectStored } = yield select(state => state.project);
    if (Object.keys(projectStored).length === 0) {
      const res = yield call(projectpage.getProject);
      const { data: { data: project = {} } = {} } = res;
      yield put({ type: GET_PROJECT._SUCCESS, project });
    }
  } catch (error) {
    yield put({ type: GET_PROJECT._ERROR, error });
    console.log('sagas > project > getProject', error);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getProjectWatcher() {
  yield takeLatest(GET_PROJECT._REQUEST, getProjectPage);
}

export default [getProjectWatcher()];
