import styled from 'styled-components';

export const ContainerDetailIcon = styled.div`
  div {
    &:nth-child(2) {
      margin-top: 10px;
      word-wrap: break-word;
    }
  }
`;
