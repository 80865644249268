import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { FaqContainer, FaqEditorialContent, FaqsBodyItem } from './style';
import { GET_FAQ } from '../../redux/actions/faq';
import { SectionTitle } from '../../atoms';
import render_body from '../../utils/render_body';
import { feBaseUrl } from '../../api/config';

const Faq = ({ getFaq, title, faq_items }) => {
  useEffect(() => {
    getFaq();
  }, [getFaq]);

  return (
    <>
      <Helmet>
        <title>Domande frequenti e FAQ sul corso - Palestre Sostenibili</title>
        <meta
          name="description"
          content="Leggi le domande più frequenti riguardanti le informazioni del corso e le modalità d'invio delle candidature."
        />
        <link rel="canonical" href={`${feBaseUrl}/faq-domande-frequenti`} />
      </Helmet>
      <FaqContainer>
        <SectionTitle title={title} />
        <FaqEditorialContent>
          {faq_items?.length > 0 && (
            <>
              {faq_items.map((faq, i) => (
                <FaqsBodyItem
                  className={`item-${faq.type}`}
                  key={`faq-item-${i}`}
                >
                  {render_body(faq)}
                </FaqsBodyItem>
              ))}
            </>
          )}
        </FaqEditorialContent>
      </FaqContainer>
    </>
  );
};

Faq.propTypes = {
  // HOC (connect, state)
  title: PropTypes.string,
  faq_items: PropTypes.array,

  // HOC (connect, dispatch)
  getFaq: PropTypes.func.isRequired
};

export default connect(
  (state) => {
    const {
      faq: { faq_items = [], title }
    } = state.faq;

    return {
      title,
      faq_items
    };
  },
  (dispatch) => ({
    getFaq: () => dispatch({ type: GET_FAQ._REQUEST })
  })
)(withMediaQueries(Faq));
