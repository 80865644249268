import styled from 'styled-components';
import { Col } from '../../ui/gridSystem';

export const RowDataContainer = styled.div`
  margin: 50px 0;
`;

export const ColumnData = styled(Col)`
  h4 {
    margin: 20px 0 10px;
  }
`;
