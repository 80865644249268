import styled from 'styled-components';

export const NewsContainer = styled.section`
  margin-top: 50px;

  @media ${props => props.theme.device.tablet},
    @media ${props => props.theme.device.mobile} {
    padding: 0 2px;
  }
`;

export const NewsFilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
  }
`;

export const NewsFilter = styled.div`
  width: 280px;
  height: 40px;
  margin-right: 20px;

  @media ${props => props.theme.device.mobile} {
    width: 100%;
    margin-right: 0;
    + div {
      margin-top: 18px;
    }
  }
`;

export const NewsListContainer = styled.div`
  margin-bottom: 45px;
  @media ${props => props.theme.device.tablet} {
    margin-bottom: 70px;
  }
`;

export const MessageOfEmpty = styled.div`
  width: 100%;
  margin-top: 80px;
  text-align: center;
`;

export const NewsItem = styled.div`
  display: inline-block;
  margin-top: 20px;
  margin-right: 20px;

  @media ${props => props.theme.device.desktop} {
    &:nth-child(4n + 0) {
      margin-right: 0;
    }
  }

  @media ${props => props.theme.device.tablet} {
    &:nth-child(3n + 0) {
      margin-right: 0;
    }
  }

  @media ${props => props.theme.device.mobile} {
    margin-right: 0;
    width: 100%;
  }
`;

export const ViewMore = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  span {
    cursor: pointer;
  }

  margin-bottom: 45px;
  @media ${props => props.theme.device.tablet} {
    margin-bottom: 70px;
  }
`;
