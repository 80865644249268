import React from 'react';
import PropTypes from 'prop-types';

import { ImageContainer, ImageItem, ImageDecoration } from './style';

const Image = ({
  imageUrl,
  position,
  x,
  y,
  size,
  onClick,
  maxWidth,
  lines,
  className
}) => {
  const decoration = lines && x !== 'none' && y !== 'none';

  return (
    <ImageContainer maxWidth={maxWidth} onClick={onClick} className={className}>
      <ImageItem src={imageUrl} />
      {decoration && (
        <ImageDecoration position={position} x={x} y={y} size={size} />
      )}
    </ImageContainer>
  );
};

Image.POSITION = {
  OUTSIDE: 'outside',
  INSIDE: 'inside'
};

Image.X = {
  NONE: 'none',
  LEFT: 'left',
  RIGHT: 'right'
};

Image.Y = {
  NONE: 'none',
  TOP: 'top',
  BOTTOM: 'bottom'
};

Image.SIZE = {
  SMALL: 'small',
  BIG: 'big',
  XSMALL: 'xsmall'
};

Image.propTypes = {
  imageUrl: PropTypes.string,
  onClick: PropTypes.func,
  position: PropTypes.oneOf(Object.values(Image.POSITION)),
  x: PropTypes.oneOf(Object.values(Image.X)),
  y: PropTypes.oneOf(Object.values(Image.Y)),
  size: PropTypes.oneOf(Object.values(Image.SIZE)),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lines: PropTypes.bool,
  className: PropTypes.string
};

Image.defaultProps = {
  position: Image.POSITION.OUTSIDE,
  x: Image.X.NONE,
  y: Image.Y.NONE,
  lines: true,
  size: Image.SIZE.BIG,
  className: ''
};

export default Image;
