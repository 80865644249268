import { apiCall, baseUrl, shouldUseMock } from './config';
import { partnersMock, partnerDetailMock } from './mock';

export default {
  getPartners: () => {
    if (shouldUseMock()) {
      return partnersMock();
    }
    return apiCall(`${baseUrl}partner`, 'GET');
  },
  getPartnerDetail: partnerId => {
    if (shouldUseMock()) {
      return partnerDetailMock();
    }
    return apiCall(`${baseUrl}partner/${partnerId}/item`, 'GET');
  }
};
