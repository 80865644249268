import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  SectionPlan,
  SectionRowImages,
  ColDetailProject,
  SectionProjectDetail,
  ProjectRow,
  ColImageProject,
  RowDescriptionImages,
  ColDescriptionImages,
  SectionProject,
  SectionText,
  SectionImageTextReverse,
  ProjectPageContainer,
  ImageArticleTitle
} from './style';
import Col from '../../ui/gridSystem/Col';
import Row from '../../ui/gridSystem/Row';
import Overpass from '../../ui/typography/overpass';

import { Image, DetailText, SectionTitle, CourseOnlineCard } from '../../atoms';
import {
  SectionDetail,
  Plans,
  HtmlRaw,
  RowImages,
  PageArticle
} from '../../components';
import ImageText from '../../components/ImageText';
import { GET_PROJECT } from '../../redux/actions/project';
import { composeDefaultButton } from '../../utils/common';
import { CardItem, CardsList } from '../OnlineCourse/style';
import { BtnsWrapper } from '../ClassroomCourse/style';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { feBaseUrl } from '../../api/config';

const Project = ({ getProject, project, mediaIsPhone }) => {
  useEffect(() => {
    getProject();
  }, [getProject]);

  const projectPage = Object.keys(project).reduce((section, key) => {
    if (!project[key] || project[key]?.length <= 0) return { ...section };
    switch (key) {
      case 'main_box':
      case 'ending_box':
      case 'main_article':
      case 'title_image_article_box':
        return {
          ...section,
          [key]: project[key][0].data
        };
      case 'plans':
      case 'central_boxes':
      case 'articles_list':
      case 'image_article_box':
      case 'title_button_box':
        return {
          ...section,
          [key]: project[key].map((e) => e.data)
        };
      case 'images_list':
        return {
          ...section,
          [key]: project[key][0].data.images
        };
      default:
        return {
          ...section,
          [key]: project[key]
        };
    }
  }, {});

  const RowImagesConfig = [
    {
      x: Image.X.LEFT,
      y: Image.Y.BOTTOM,
      position: Image.POSITION.OUTSIDE,
      size: Image.SIZE.SMALL
    },
    {
      lines: false
    },
    {
      x: Image.X.RIGHT,
      y: Image.Y.TOP,
      position: Image.POSITION.INSIDE
    }
  ];

  const isEven = (number) => {
    const n = number + 1;
    return n % 2 === 0;
  };

  const { image_article_box } = projectPage;
  const formatted_image_article_box = (image_article_box || []).reduce(
    (acc, val) => [
      ...acc,
      {
        ...val.article[0].data,
        ...val.file[0].data.image
      }
    ],
    []
  );

  return (
    <>
      <Helmet>
        <title>Info sul corso, programma e iscrizione - Palestre Sostenibili</title>
        <meta
          name="description"
          content="Consulta il bando di Palestre Sostenibili, le informazioni sul corso per la sostenibilità aziendale e come iscriversi."
        />
        <link rel="canonical" href={`${feBaseUrl}/corso-palestre-sostenibili`} />
      </Helmet>
      <ProjectPageContainer>
        <SectionProject>
          {projectPage?.title && <SectionTitle title={projectPage?.title} />}
          {projectPage?.main_box && (
          <PageArticle
            title={projectPage?.main_box?.article[0]?.data?.title}
            body={projectPage?.main_box?.article[0]?.data?.body}
            image_url={projectPage?.main_box?.file[0]?.data?.image?.url}
            video_file={projectPage?.main_box?.file[0]?.data?.video?.url}
            type={
            projectPage?.main_box?.file[0]?.data?.video?.url
              ? 'video'
              : 'image'
          }
          />
          )}
        </SectionProject>
        {(projectPage?.main_article?.title
        || projectPage?.main_article?.body) && (
          <SectionText>
            <Row>
              {projectPage?.main_article?.title && (
              <Col
                lg={projectPage?.main_article?.body ? 5 : 12}
                md={projectPage?.main_article?.body ? 8 : 12}
              >
                <SectionDetail text={projectPage?.main_article?.title} />
              </Col>
              )}
              {projectPage?.main_article?.body && (
              <Col lg={5} md={12}>
                <Overpass type="text">
                  <HtmlRaw html={projectPage?.main_article?.body} />
                </Overpass>
              </Col>
              )}
            </Row>
          </SectionText>
        )}
        {(projectPage?.images_list || projectPage?.articles_list) && (
        <SectionRowImages>
          {projectPage?.images_list && (
            <RowImages
              images={projectPage.images_list}
              configuration={RowImagesConfig}
            />
          )}
          {projectPage?.articles_list && (
            <RowDescriptionImages>
              {projectPage.articles_list.map((item, i) => (
                <ColDescriptionImages
                  even={isEven(i)}
                  lg={4}
                  md={12}
                  key={`ColDescriptionImages-${i}`}
                >
                  <Overpass type="projectTitleColumn" htmlAttribute="h4">
                    {item.title}
                  </Overpass>
                  <Overpass type="projectTextColumn">
                    <HtmlRaw html={item.body} />
                  </Overpass>
                </ColDescriptionImages>
              ))}
            </RowDescriptionImages>
          )}
        </SectionRowImages>
        )}
        {formatted_image_article_box?.length > 0 && (
        <>
          <ImageArticleTitle>
            <Overpass
              type={
                mediaIsPhone ? 'sectionHeaderTitleMobile' : 'sectionHeaderTitle'
              }
            >
              {projectPage?.title_image_article_box?.title}
            </Overpass>
          </ImageArticleTitle>
          <CardsList>
            {[...formatted_image_article_box].map((item, index) => (
              <CardItem key={`CourseOnlineCard-${index}`}>
                <CourseOnlineCard card={item} />
              </CardItem>
            ))}
          </CardsList>
        </>
        )}
        {projectPage?.title_button_box?.length > 0 && (
        <BtnsWrapper>
          {projectPage?.title_button_box.map((item, i) => (
            <SectionDetail
              key={`title-botton-${i}`}
              text={item?.title}
              button={composeDefaultButton(
                item?.button?.title,
                item?.button?.uri
              )}
            />
          ))}
        </BtnsWrapper>
        )}
        {projectPage?.central_boxes && (
        <SectionImageTextReverse>
          {projectPage.central_boxes.map((row, index) => (
            <ImageText
              key={`ImageText-${index}`}
              title={row?.article?.[0]?.data?.title}
              text={row?.article?.[0]?.data?.body}
              image={row?.file?.[0]?.data?.image?.url}
              video={row?.file?.[0]?.data?.video?.url}
              reverse={row?.media_position === 'Left'}
              button={composeDefaultButton(row.button.title, row.button.uri)}
            />
          ))}
        </SectionImageTextReverse>
        )}
        {projectPage?.plans && (
        <SectionPlan>
          <Plans plans={projectPage?.plans || []} />
        </SectionPlan>
        )}
        {projectPage?.ending_box && (
        <SectionProjectDetail>
          <ProjectRow>
            <Col lg={5} md={12}>
              <SectionDetail text={projectPage?.ending_box?.title} />
            </Col>
            <Col lg={7} md={12}>
              <Row>
                {projectPage?.ending_box?.articles && (
                  <ColDetailProject lg={5} md={5}>
                    {[...projectPage.ending_box.articles].map(({ data }, i) => (
                      <DetailText
                        key={`DetailText-${i}`}
                        text={data.body}
                        number={data.title}
                      />
                    ))}
                  </ColDetailProject>
                )}
                <ColImageProject lg={7} md={7}>
                  {projectPage?.ending_box?.image?.url && (
                    <Image
                      imageUrl={projectPage.ending_box.image.url}
                      x="right"
                      y="top"
                      maxWidth="360"
                    />
                  )}
                </ColImageProject>
              </Row>
            </Col>
          </ProjectRow>
        </SectionProjectDetail>
        )}
      </ProjectPageContainer>
    </>
  );
};

Project.propTypes = {
  mediaIsPhone: PropTypes.bool,
  // HOC (connect, state)
  project: PropTypes.object.isRequired,
  // HOC (connect, dispatch)
  getProject: PropTypes.func.isRequired
};

export default connect(
  (state) => {
    const { project } = state.project;

    return {
      project
    };
  },
  (dispatch) => ({
    getProject: () => dispatch({ type: GET_PROJECT._REQUEST })
  })
)(withMediaQueries(Project));
