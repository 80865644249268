import styled, { css } from 'styled-components';

export const Bar1 = styled.div`
  width: 25px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.primary.white};
  margin: 6px 0;
  transition: 0.4s;
`;

export const Bar2 = styled(Bar1)`
  width: 18px;
  margin-left: auto;
`;
export const Bar3 = styled(Bar1)``;

export const MenuContainer = styled.div`
  display: inline-block;
  cursor: pointer;

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${Bar1} {
        -webkit-transform: rotate(-45deg) translate(-6px, 6px);
        transform: rotate(-45deg) translate(-6px, 6px);
      }
      ${Bar2} {
        opacity: 0;
      }
      ${Bar3} {
        -webkit-transform: rotate(45deg) translate(-5px, -5px);
        transform: rotate(45deg) translate(-5px, -5px);
      }
    `}
`;
