import React from 'react';
import PropTypes from 'prop-types';

import Overpass from '../../ui/typography/overpass';
import { ButtonContainer } from './style';

const Button = ({
  btnText,
  disabled,
  onClick,
  btnType,
  minHeight,
  minWidth,
  className,
  customStyle
}) => (
  <ButtonContainer
    disabled={disabled}
    onClick={onClick}
    btnType={btnType}
    minHeight={minHeight}
    className={className}
    minWidth={minWidth}
    style={customStyle}
  >
    <Overpass htmlAttribute="span" type="buttonText">
      {btnText}
    </Overpass>
  </ButtonContainer>
);

Button.TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  FOURTH: 'fourth'
};

Button.propTypes = {
  className: PropTypes.string,
  minHeight: PropTypes.number,
  minWidth: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  btnText: PropTypes.string,
  customStyle: PropTypes.object,
  btnType: PropTypes.oneOf(Object.values(Button.TYPE))
};

Button.defaultProps = {
  className: '',
  minHeight: 40,
  minWidth: 180,
  disabled: false,
  btnType: Button.TYPE.PRIMARY
};

export default Button;
