import { createAsyncActionType } from './index';

export const GET_COURSE_ONLINE = createAsyncActionType(
  'plan',
  'GET_COURSE_ONLINE'
);
export const GET_COURSE_CLASSROOM = createAsyncActionType(
  'plan',
  'GET_COURSE_CLASSROOM'
);
