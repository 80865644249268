import React from 'react';

import PropTypes from 'prop-types';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { Icon, Button } from '../../atoms';
import { Row } from '../../ui/gridSystem';
import Overpass from '../../ui/typography/overpass';
import {
  ContainerQuote,
  QuoteImage,
  Image,
  ColQuoteText,
  PeopleContainer,
  ColQuoteIcon,
  RowButton
} from './style';
import HtmlRaw from '../HtmlRaw';

const Quote = ({
  image,
  text,
  title,
  people,
  detail,
  button,
  mediaIsPhone
}) => (
  <ContainerQuote>
    <Overpass type="partnerTitleQuote" htmlAttribute="div">
      <HtmlRaw html={title} />
    </Overpass>
    <QuoteImage>
      <Image src={image} />
    </QuoteImage>
    <Row className="no-gutters w-100">
      <ColQuoteIcon type="open" lg={1} md={1} sm={12} xs={12}>
        <Icon size={mediaIsPhone ? 56 : 70} type="icon-aperte" />
      </ColQuoteIcon>
      <ColQuoteText lg={10} md={10} sm={12} xs={12}>
        <Overpass type="partnerTextQuote">
          <HtmlRaw html={text} />
        </Overpass>
        <PeopleContainer>
          <Overpass>{people}</Overpass>
          <Overpass type="detailText">{detail}</Overpass>
        </PeopleContainer>
      </ColQuoteText>
      <ColQuoteIcon type="close" lg={1} md={1} sm={12} xs={12}>
        <Icon size={mediaIsPhone ? 56 : 70} type="icon-chiuse" />
      </ColQuoteIcon>
      {button && (
        <RowButton>
          <Button
            onClick={button.onClick}
            btnText={button.btnText}
            btnType={Button.TYPE.SECONDARY}
          />
        </RowButton>
      )}
    </Row>
  </ContainerQuote>
);

Quote.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  people: PropTypes.string,
  detail: PropTypes.string,
  button: PropTypes.object,

  // HOC (withMediaQueries)
  mediaIsPhone: PropTypes.bool.isRequired
};

export default withMediaQueries(Quote);
