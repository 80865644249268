export const breakpoint = {
  mobile: 320,
  tablet: 768,
  desktop: 1024,
  desktopL: 1280
};

const size = {
  mobile: `${breakpoint.mobile}px`,
  tablet: `${breakpoint.tablet}px`,
  desktop: `${breakpoint.desktop}px`,
  desktopL: `${breakpoint.desktopL}px`
};

const device = {
  mobile: `(min-width: ${size.mobile}) and (max-width: calc(${size.tablet} - 1px))`,
  tablet: `(min-width: ${size.tablet}) and (max-width: calc(${size.desktop} - 1px))`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop}) and (max-width: calc(${size.desktopL} - 1px))`
};

export default device;
