export function createAsyncActionType(section = '', type = '') {
  return {
    _REQUEST: `@@${section.toLowerCase()}/${type.toUpperCase()}_REQUEST`,
    _SUCCESS: `@@${section.toLowerCase()}/${type.toUpperCase()}_SUCCESS`,
    _ERROR: `@@${section.toLowerCase()}/${type.toUpperCase()}_ERROR`
  };
}

// app
export const LOADER_OPEN = '@@global/LOADER_OPEN';
export const LOADER_CLOSE = '@@global/LOADER_CLOSE';

// modal
export const MODAL_OPEN = '@@global/MODAL_OPEN';
export const MODAL_CLOSE = '@@global/MODAL_CLOSE';
