import styled from 'styled-components';

export const ContainerDetailText = styled.div`
  max-width: 100%;
  div {
    &:nth-child(2) {
      margin-top: 10px;
    }
  }
`;
