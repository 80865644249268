import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from '../../hoc/withMediaQueries';
import { ButtonInterface } from '../../interface/button';
import { SectionDetailContainer } from './style';
import { Button, SectionTitle } from '../../atoms';
import Overpass from '../../ui/typography/overpass';
import { HtmlRaw } from '..';

const SectionDetail = ({ title, text, button, mediaIsPhone, className }) => (
  <SectionDetailContainer className={className}>
    {title && <SectionTitle title={title} />}
    <Overpass
      type={mediaIsPhone ? 'partnerTitleMobile' : 'partnerTitleDesktop'}
    >
      {text?.startsWith('<p>') ? <HtmlRaw html={text} /> : text}
    </Overpass>
    {button && (
      <Button
        onClick={button.onClick}
        btnType={Button.TYPE.SECONDARY}
        btnText={button.btnText}
      />
    )}
  </SectionDetailContainer>
);
SectionDetail.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  button: ButtonInterface,
  mediaIsPhone: PropTypes.bool,
  className: PropTypes.string
};

export default connect()(withMediaQueries(SectionDetail));
